import { Col, Row, Table, Card, Select, Button } from "antd";
import "moment/locale/es";
import { ButtonExcel } from "components/otros/buttons";
import { useInventarioActual } from "hooks/inventarios/historicos/useInventarioActual";

export function InventarioActual() {
  const {
    columns,
    filteredData,
    handleClickDownloadData,
    datosPeriodos,
    setBodega,
    bodega,
    dataEstado,
    estadoBotones,
    handleChangeButton,
    calcularTotales,
  } = useInventarioActual();

  return (
    <Row style={{ padding: "16px", marginRight: "0px", backgroundColor: "#fff" }} gutter={[16, 16]}>
      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <Select
          placeholder='Selecione una bodega'
          style={{ width: 250, marginRight: "10px", marginTop: "0px" }}
          onChange={(value) => setBodega(value)}
          options={datosPeriodos}
        />

        <Button
          type='primary'
          style={{ marginLeft: "5px" }}
          //icon={<PoweroffOutlined />}
          loading={estadoBotones}
          disabled={bodega === undefined}
          onClick={handleChangeButton}
        >
          Generar reporte
        </Button>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <Row gutter={[10, 10]} style={{ marginBottom: "20px", display: "flex", justifyContent: "flex-end" }}>
          <Col xs={24} md={10}>
            {dataEstado && <ButtonExcel buttonProps={{ onClick: handleClickDownloadData }} />}
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        {dataEstado && (
          <Card>
            <Table
              columns={columns}
              dataSource={filteredData}
              scroll={{ x: true }}
              size='small'
              pagination={{ pageSize: 10, hideOnSinglePage: true, position: ["bottomCenter"] }}
              summary={() => {
                const { totalItems, unid } = calcularTotales();
                return (
                  <Table.Summary fixed>
                    <Table.Summary.Row style={{ backgroundColor: "#f0f0f0" }}>
                      <Table.Summary.Cell index={0}>Total items:</Table.Summary.Cell>
                      <Table.Summary.Cell index={1}>{`${totalItems}`}</Table.Summary.Cell>
                      <Table.Summary.Cell index={2}></Table.Summary.Cell>
                      <Table.Summary.Cell index={3}></Table.Summary.Cell>
                      <Table.Summary.Cell index={4}></Table.Summary.Cell>
                      <Table.Summary.Cell index={5}></Table.Summary.Cell>
                      <Table.Summary.Cell index={6}></Table.Summary.Cell>
                      <Table.Summary.Cell align='right' index={7}>
                        Total costo:
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align='right' index={8}>
                        ${`${unid}`}
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                );
              }}
            />
          </Card>
        )}
      </Col>
    </Row>
  );
}

export default InventarioActual;
