import { ColumnsType } from 'antd/lib/table';
import { url } from 'config/constants';
import { optionsPost } from 'config/methods';
import { useExport } from 'hooks/otros/useExport';
import { useSearchTableNew } from 'hooks/otros/useSearchTableNew';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react'

export type AcumulacionPendiente = {
    key: React.Key,
    cod_bodega: number,
    descripcion_sri: string,
    establecimiento: string,
    cedula: string,
    nombres: string,
    fecha_factura: Date,
    loyalty_id: number,
    is_acumulable: string,
    type: string,
    fraccion: number,
    cantidad: number,
    codigo_homologado: string,
    cod_FSG: number,
    cod_Mia: string,
    descripcion_producto: string,
    saldo: string
}

export function useReporteAcumulacionPendiente() {
    const [DataAcumulacionPendiente, setDataAcumulacionPendiente] = useState<AcumulacionPendiente[]>([])
    const [estado,setestado]=useState(false)
    const [fechasSeleccionadas, setFechasSeleccionadas] = useState<[moment.Moment, moment.Moment]>()
     const { getColumnSearchPropsNew } = useSearchTableNew<AcumulacionPendiente>()

    const { exportToExcel } = useExport()
    const handleDateChange = (dates: any, dateStrings: any) => {
        setFechasSeleccionadas(dates)
    }


    const getAcumulacionPendiente=useCallback(async () => {
        try {
            setestado(false)
            const res = await fetch(
            `${url}fapifsg-pr/productos/acumulacion-pendiente`,
            optionsPost(
                {
                fecha_inicio: fechasSeleccionadas ? fechasSeleccionadas[0].format("DD/MM/YYYY HH:mm:ss") : "",
                fecha_fin: fechasSeleccionadas ? fechasSeleccionadas[1].format("DD/MM/YYYY HH:mm:ss") : "",
                }),
            ).then((data) => data.json())
            
            let newdata: any = []
          if (res) {
            setestado(true)
            newdata = Array.from(res).map((item: any, key: any) => ({
                key: key,
                cod_bodega: item.Cod_Bodega,
                descripcion_sri: item.Descripcion_Sri,
                establecimiento: item.Establecimiento,
                cedula: item.Cedula,
                nombres: item.Nombres,
                fecha_factura: item.Fecha_factura,
                loyalty_id: item.Loyalty_id,
                is_acumulable: item.Is_ACUMULABLE,
                type: item.typo,
                fraccion: item.Fraccion,
                cantidad: (item.Cantidad).toFixed(4),
                codigo_homologado: item.codigo_homologado,
                cod_FSG: item.Cod_FSG,
                cod_Mia: item.Cod_MIA,
                descripcion_producto: item.Descripcion,
                saldo: (item.Saldo).toFixed(4)
            }))
        }
        setDataAcumulacionPendiente(newdata)
        setestado(true)    
        } catch (error) {
            console.error(error)
        }
        
    }, [fechasSeleccionadas])

    const columns: ColumnsType<AcumulacionPendiente> = [
            { title: "Codigo Bodega", dataIndex: "cod_bodega", className: "fontsize", ...getColumnSearchPropsNew("cod_bodega") },
            { title: "Descripción SRI", dataIndex: "descripcion_sri", className: "fontsize", align: "center", ...getColumnSearchPropsNew("descripcion_sri") },
            { title: "Establecimiento", dataIndex: "establecimiento", className: "fontsize", align: "center" },
            { title: "Cedula", dataIndex: "cedula", className: "fontsize", ...getColumnSearchPropsNew('cedula') },
            { title: "Nombres", dataIndex: "nombres", className: "fontsize", width: "300px", ...getColumnSearchPropsNew('nombres') },
            { title: "Fecha Factura", dataIndex: "fecha_factura", className: "fontsize" },
            { title: "Loyalty_id", dataIndex: "loyalty_id", className: "fontsize" },
            { title: "Is Acumulable", dataIndex: "is_acumulable", className: "fontsize" },
            { title: "Type", dataIndex: "type", className: "fontsize", ...getColumnSearchPropsNew("type") },
            { title: "Fracción", dataIndex: "fraccion", className: "fontsize",align:'right'},
            { title: "Cantidad", dataIndex: "cantidad", className: "fontsize", align:'right'},
            { title: "Cod Homologado", dataIndex: "codigo_homologado", className: "fontsize" },
            { title: "Cod FSG", dataIndex: "cod_FSG", className: "fontsize" },
            { title: "Cod Mia ", dataIndex: "cod_Mia", className: "fontsize" },
            { title: "Producto", dataIndex: "descripcion_producto", className: "fontsize", ...getColumnSearchPropsNew("descripcion_producto") },
            { title: "Saldo", dataIndex: "saldo", className: "fontsize", align: 'right'},
          ]

       const disableFutureDates = (current: any) => {
            return current && current > moment().endOf('day');
          };
          const disableFutureTimes = (selectedDate: any) => {
            if (!selectedDate) {
              return {};
            }
            const now = moment();
            if (selectedDate.isSame(now, 'day')) {
              return {
                disabledHours: () => Array.from({ length: 24 }, (_, i) => i).filter(hour => hour > now.hour()),
                disabledMinutes: (selectedHour: number) => {
                  if (selectedHour === now.hour()) {
                    // Si es la hora actual, bloquear minutos mayores al minuto actual
                    return Array.from({ length: 60 }, (_, i) => i).filter(min => min > now.minute());
                  }
                  // Si es una hora diferente, no bloquear minutos
                  return [];
                },
              };
            }
          
            return {};
          };

          useEffect(() => {
            getAcumulacionPendiente()
          }, [getAcumulacionPendiente])

          const handleDownload = () => {    
            const dataExport = DataAcumulacionPendiente.map((item)=>{
                return{
                    "Cod_Bodega": item.cod_bodega,
                    "Descripcion_Sri": item.descripcion_sri,
                    "Establecimiento": item.establecimiento,
                    "Cedula": item.cedula,
                    "Nombres": item.nombres,
                    "Fecha_Factura": item.fecha_factura,
                    "Loyalty_id": item.loyalty_id,
                    "is_acumulable": item.is_acumulable,
                    "typo (nc, venta, bonificacion)": item.type,
                    "Unidad medida": item.fraccion,
                    "Cantidad": item.cantidad,
                    "codigo_homologacion": item.codigo_homologado,
                    "Cod_FSG": item.cod_FSG,
                    "Cod_MIA": item.cod_Mia,
                    "Descripcion": item.descripcion_producto,
                    "Saldo": item.saldo
                }
            })
            exportToExcel(dataExport, `ReporteAcumulacionPendiente${moment().format("DDMMYYYY")}`)
          } 

  return {
    disableFutureDates,
    disableFutureTimes,
    handleDateChange,
    fechasSeleccionadas,
    estado,
    DataAcumulacionPendiente,
    columns,
    handleDownload
  }
}
