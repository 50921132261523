import { Moment } from "moment";
import { useCallback, useEffect, useState } from "react";
import { optionsPOST, url_interna } from "../../config/constants";

import { useVerifyResponse } from "hooks/otros/useVerifyReponse";
import moment from "moment";
import { Alert, TableColumnsType } from "antd";

import { useLayout } from "hooks/otros/useLayout";

import { useSearchTableNew } from "hooks/otros/useSearchTableNew";
import { optionsGet, swalError } from "config/methods";
import { useExport } from "hooks/otros/useExport";

type InventarioReporte = {
  key: React.Key;
  fecha_compra: string;
  Cod_Producto: string;
  Descripcion: string;
  tipo: string;
  U_Medida: number;
};

export interface DatosPeriodo {
  label: string;
  value: number;
}
const breadcrumbs = [{ Label: "Inventarios", Url: "/inventario" }, { Label: "Inconsistencias" }];

const openKeys = [breadcrumbs[0].Label];
const selectKeySider = `${breadcrumbs[0].Label}/${breadcrumbs[1].Label}`;

export function useInconsistenciasConsignacion() {
  useLayout(breadcrumbs, openKeys, selectKeySider);

  const [datos, setDatos] = useState<InventarioReporte[]>([]);
  const [searchText] = useState("");
  const [filteredData, setFilteredData] = useState<InventarioReporte[]>([]);
  const [[fechaInicio, fechaFin], setFechas] = useState<[Moment | null, Moment | null]>([null, null]);
  const [dataEstado, setDataEstado] = useState<boolean>(false);

  const [estadoBotonNuevo, setEstadoBotonNuevo] = useState<boolean>(false);

  const { exportToExcel } = useExport();
  const [estadoBotones, setDataBoton] = useState<boolean>(false);
  const [datosPeriodos, setDatosPeriodos] = useState<DatosPeriodo[]>([]);
  const { getColumnSearchPropsNew: getColumnSearchPropsNewVisorReporte } = useSearchTableNew<InventarioReporte>();

  const { isError } = useVerifyResponse();

  const getDatosCuentas = async (fechaInicio: Moment, fechaFin: Moment) => {
    setDataEstado(false);
    setDatos([]);
    setDataBoton(true);
    setEstadoBotonNuevo(false);
    try {
      const res = await fetch(
        url_interna + "api/inventarios/obtener-inconsistencias",
        optionsPOST(
          JSON.stringify({
            fechaInicio: fechaInicio!.format("DD/MM/YYYY"),
            fechaFin: fechaFin!.format("DD/MM/YYYY"),
          }),
          {
            "api-token": "apitoken",
          }
        )
      );
      if (isError(res)) throw new Error();
      const respuesta = await res.json();

      if (respuesta.msg) {
        const respuestas: InventarioReporte[] = respuesta.data;
        console.log(respuesta);
        setDataBoton(false);
        setDataEstado(true);
        setDatos(respuestas);
      } else {
        setDatos([]);
        setDataBoton(false);
        setDataEstado(true);
        swalError({ text: "No se pudieron obtener registros en la fecha seleccionada" });
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      return { msg: "no_conexion", status: false };
    }
  };
  const disabledDate = (current: moment.Moment) => {
    const currentYear = moment().year(); // Año actual

    if (!fechaInicio) {
      // Si no hay fechaInicio, deshabilitar fechas futuras y años distintos al actual
      return current && (current.year() !== currentYear || current > moment().endOf("day"));
    }

    const minDate = fechaInicio.clone().startOf("day");
    const maxDate = fechaInicio.clone().add(6, "months").add(1, "days").endOf("day");
    const today = moment().endOf("day"); // Fecha actual (final del día)

    // Deshabilitar fechas fuera del rango permitido o años distintos al actual
    return current && (current.year() !== currentYear || current < minDate || current > maxDate || current > today);
  };

  // Función para determinar el estilo basado en el estado
  const getOptionColor = (estado: number) => {
    switch (estado) {
      case 1:
        return "#ff4d4f"; // Rojo para estado 1
      case 2:
        return "#52c41a"; // Verde para estado 2
      default:
        return "#d9d9d9"; // Gris para otros estados
    }
  };

  const handleClickDownloadData = () => {
    if (filteredData.length > 0) {
      let datosParaExportar = [];

      datosParaExportar = filteredData.map(({ ...resto }) => resto);

      datosParaExportar = datosParaExportar.map(({ key, Cod_Producto, ...resto }) => resto);
      exportToExcel(
        datosParaExportar,
        "Reporte de cuenta " + fechaInicio!.format("DD/MM/YYYY") + " " + fechaFin!.format("DD/MM/YYYY") + " "
      );
    }
  };

  const formateaCantidad = (cantidad: number) => {
    const totalCostoFormateado = (cantidad / 1).toLocaleString("de-DE", {
      minimumFractionDigits: 4,
      maximumFractionDigits: 4,
    });
    return totalCostoFormateado;
  };
  const handleChangeButton = () => {
    try {
      if (fechaInicio && fechaFin) {
        getDatosCuentas(fechaInicio, fechaFin);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (!searchText) {
      setFilteredData(datos); // Si no hay texto de búsqueda, muestra todos los datos
    } else {
      const filtered = datos.filter((item) => item.Descripcion?.toLowerCase().includes(searchText.toLowerCase()));
      setFilteredData(filtered);
    }
  }, [searchText, datos]);
  // UseEffect para actualizar los datos al cambiar el mes
  const columns: TableColumnsType<InventarioReporte> = [
    {
      title: "Fecha",
      dataIndex: "fecha_compra",
      key: "fecha_compra",
      className: "letraPequenaVisor2",
      width: "135px",
    },
    {
      title: "Cod_Mia",
      dataIndex: "Cod_Producto",
      key: "Cod_Producto",
      className: "letraPequenaVisor2",
      width: "95px",
      ...getColumnSearchPropsNewVisorReporte("Cod_Producto"),
    },
    {
      title: "Producto",
      dataIndex: "descripcion",
      key: "descripcion",
      className: "letraPequenaVisor2",
      ...getColumnSearchPropsNewVisorReporte("Descripcion"),
      width: "350px",
    },
    {
      title: "U_Medida",
      dataIndex: "U_Medida",
      key: "U_Medida",
      className: "letraPequenaVisor2",
      align: "right",
      width: "85px",
    },
    {
      title: "Observación",
      dataIndex: "tipo",
      key: "tipo",
      className: "letraPequenaVisor2",
      render: (tipo) => {
        return tipo === 1 ? (
          <Alert
            message='Producto nuevo en SG o el código MIA no ha sido homologado.'
            // description='Este producto es nuevo o el código MIA no ha sido homologado.'
            type='warning'
            showIcon
          />
        ) : (
          <Alert
            message='Producto homologado, pero inactivo actualizar parámetros.'
            // description='Este producto está homologado, pero actualmente inactivo. Es necesario actualizar su unidad de medida, precios u otros parámetros.'
            type='info'
            showIcon
          />
        );
      },
    },
  ];

  return {
    columns,
    fechaInicio,
    fechaFin,
    setFechas,
    filteredData,
    datosPeriodos,
    estadoBotones,
    dataEstado,
    handleClickDownloadData,
    disabledDate,
    estadoBotonNuevo,
    getOptionColor,
    handleChangeButton,
  };
}
