import { type TableColumnsType, Tag } from "antd"
import { type DefaultOptionType } from "antd/lib/select"
import { url } from "config/constants"
import { optionsGet } from "config/methods"
import { useExport } from "hooks/otros/useExport"
import { useLayout } from "hooks/otros/useLayout"
import { useLocalStorage } from "hooks/otros/useLocalStorage"
import { useSearchTable } from "hooks/otros/useSearchTable"
import { useSwal } from "hooks/otros/useSwal"
import { useVerifyResponse } from "hooks/otros/useVerifyReponse"
import moment from "moment"
import type { Moment } from "moment"
import {  useEffect, useState } from "react"

type DocumentosPendientes = {
  ruc: string
  factura: string
  Cod_Bodega: string
  bodega: string
  fecha: string
  base_0: number
  base_iva: number
  iva: number
  total: number
  retencion: number
  Cod_Diario:number
  p:number
}

type GruposProveedores = {
  cod_grupo: number
  grupo: string
}

const breadcrumbs = [
  { Label: "Reportes", Url: "/reportes" },
  { Label: "Proveedores", Url: "/reportes/proveedores" },
  { Label: "Documentos pendientes por grupo" },
]
const openKeys = [breadcrumbs[0].Label, `${breadcrumbs[0].Label}/${breadcrumbs[1].Label}`]
const selectKeySider = `${breadcrumbs[1].Label}/${breadcrumbs[2].Label}`

export function useFacturasPendientesGruposProveedores() {
  useLayout(breadcrumbs, openKeys, selectKeySider)
  
  const [[fechaInicio, fechaFin], setFechas] = useState<[Moment | null, Moment | null]>([null, null]);
  
  const [estadoBotones, setDataBoton] = useState<boolean>(false);

  //const [fechaCorte, setFechaCorte] = useState<Moment>(moment())
  const [documentosPendientes, setDocumentosPendientes] = useState<DocumentosPendientes[]>([])
  const [pageSize, setPageSize] = useState(5)
  const [grupos, setGrupos] = useState<GruposProveedores[]>()
  const [selectedGrupo, setSelectedGrupo] = useState(0)

  const { exportToExcel } = useExport()

  const { getColumnSearchProps } = useSearchTable<DocumentosPendientes>()

  const { optionsPost } = useLocalStorage()

  const { swalError } = useSwal()

  const { isError } = useVerifyResponse()

  useEffect(() => {
    ;(async () => {
      try {
        const res = await fetch(`${url}apifsg-pr/proveedores/grupos`, optionsGet())
        if (isError(res)) {
          throw new Error()
        }
        const result = await res.json()
        setGrupos(() => result)
      } catch (e) {
        swalError({ text: "No se pudo obtener la lista de los grupos de proveedores" })
      }
    })()
  }, [isError, swalError, optionsGet])

  /*const reFetcher = useCallback(async () => {
    try {
      if (!Boolean(selectedGrupo) || !fechaInicio || !fechaFin) return
      const response = await fetch(
        `${url}apifsg-pr/proveedores/grupos/documentos-pendientes`,
        optionsPost({ grupo: selectedGrupo, fecha_inicio: fechaInicio.format("DD/MM/YYYY"),fechaFin: fechaFin.format("DD/MM/YYYY") })
      )
      if (isError(response)) {
        throw new Error()
      }
      const result = await response.json()

      setDocumentosPendientes(result)
    } catch (e) {
      swalError({ text: "No se pudo obtener la lista de los documentos pendientes" })
    }
  }, [isError, optionsPost, swalError, fechaCorte, selectedGrupo])*/

   const getCargosTransferencias = async (fechaInicio: Moment, fechaFin: Moment) => {
      setDataBoton(true);
      try {
        const response = await fetch(
          `${url}apifsg-pr/proveedores/grupos/documentos-pendientes`,
          optionsPost({ grupo: selectedGrupo, fecha_inicio: fechaInicio.format("DD/MM/YYYY"),fechaFin: fechaFin.format("DD/MM/YYYY") })
        )
        if (isError(response)) throw new Error();
        const respuesta = await response.json();
          setDocumentosPendientes(respuesta);
          setDataBoton(false);
          return { msg: "Ocurrio un error", status: false };
      } catch (e) {
        swalError({ text: "No se pudo obtener la lista de los documentos pendientes" })
      }
    };
  
  const handleChangeButton = () => {
    try {
      if (fechaInicio && fechaFin) {
       getCargosTransferencias(fechaInicio, fechaFin);
      }
    } catch (error) {
      console.log(error);
    }
  };

   const disabledDate = (current: moment.Moment) => {
      if (!fechaInicio) {
        return current && current >= moment().endOf("day"); // Mientras no haya fecha inicial, deshabilita fechas futuras
      }
      const minDate = fechaInicio.clone().startOf("day");
      const maxDate = fechaInicio.clone().add(6, "months").add(1, "days").endOf("day");
      const today = moment().startOf("day"); // Fecha actual (final del día)
  
      return current && (current < minDate || current > maxDate || current > today);
    };
  const columnsTable: TableColumnsType<DocumentosPendientes> = [
    {
      title: "Ruc",
      dataIndex: "ruc",
      key: "ruc",
      ...getColumnSearchProps("ruc"),
    },
    {
      title: "Factura",
      dataIndex: "factura",
      key: "factura",
      ...getColumnSearchProps("factura"),
    },
    {
      title: "Bodegas",
      dataIndex: "bodega",
      key: "bodega",
      ...getColumnSearchProps("bodega"),
    },
    {
      title: "Fecha",
      dataIndex: "fecha",
      key: "fecha",
      ...getColumnSearchProps("fecha"),
    },
    {
      title: "Base 0",
      dataIndex: "base_0",
      key: "base_0",
    },
    {
      title: "Base Iva",
      dataIndex: "base_iva",
      key: "base_iva",
    },
    {
      title: "Iva",
      dataIndex: "iva",
      key: "iva",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },
    {
      title: "Retención",
      dataIndex: "retencion",
      key: "retencion",
    },
    {
      title: "Cod diario",
      dataIndex: "Cod_Diario",
      key: "Cod_Diario",
    },
  ]

  const handleDownload = () => {
    const dataExport = documentosPendientes.map(
      ({ ruc, factura,Cod_Bodega, bodega, fecha, base_0, base_iva, iva, total, retencion, Cod_Diario,p }) => ({
        Ruc: ruc,
        Factura: factura,
        "Cod Bodega": Cod_Bodega,
        Bodega: bodega,
        Fecha: fecha,
        "Base 0": base_0,
        "Base Iva": base_iva,
        Iva: iva,
        Total: total,
        Retención: retencion,
        "Código de Diario": Cod_Diario,
        "subtotal": p
      })
    )
    exportToExcel(
      dataExport,
      `Documentos pendientes de ${grupos?.find(g => g.cod_grupo === selectedGrupo)?.grupo} ${fechaInicio!.format("DD/MM/YYYY")} - ${fechaFin!.format("DD/MM/YYYY")}`
    )
  }


  return {
    grupos,
    selectedGrupo,
    setSelectedGrupo,
    /*fechaCorte,
    setFechaCorte,*/
    handleDownload,
    columnsTable,
    documentosPendientes,
    pageSize,
    setPageSize,
    fechaInicio,
    fechaFin,
    setFechas,
    disabledDate,
    handleChangeButton,
    estadoBotones,
  }
}
