import { Moment } from "moment";
import { useCallback, useEffect, useState } from "react";
import { optionsGET, url_interna } from "../../config/constants";

import { useVerifyResponse } from "hooks/otros/useVerifyReponse";

import { TableColumnsType } from "antd";

import { useLayout } from "hooks/otros/useLayout";

import { useSearchTableNew } from "hooks/otros/useSearchTableNew";
import { useExport } from "hooks/otros/useExport";

type Productos = {
  key: React.Key;
  Fecha_Registro: string;
  Cod_Producto: number;
  Cod_MIA: string;
  Descripcion: string;
  Cod_Barra: string;
  laboratorio: string;
  IVA: string;
  PVP: number;
  PVF: number;
  U_Medida: string;
  rebate: number;
};

export interface DatosPeriodo {
  label: string;
  value: number;
}
const breadcrumbs = [{ Label: "Reportes", Url: "/productos" }, { Label: "Productos Billeteate" }];

const openKeys = [breadcrumbs[0].Label];
const selectKeySider = `${breadcrumbs[0].Label}/${breadcrumbs[1].Label}`;

export function useProductosBilleteate() {
  useLayout(breadcrumbs, openKeys, selectKeySider);

  const [[fechaInicio, fechaFin], setFechas] = useState<[Moment | null, Moment | null]>([null, null]);

  const [tipoMovimiento, setTipoMovimiento] = useState<number>();
  const { exportToExcel } = useExport();

  const [productos, setProductos] = useState<Productos[]>([]);
  const { getColumnSearchPropsNew: getColumnSearchPropsNewVisorReporte } = useSearchTableNew<Productos>();

  const { isError } = useVerifyResponse();

  const getDatosIniciales = useCallback(async () => {
    setProductos([]);
    try {
      const respuesta = await fetch(url_interna + "api/reportes/productos_billeteate", optionsGET).then((data) =>
        data.json()
      );

      if (respuesta.msg) {
        const res: Productos[] = respuesta.data;

        if (res.length > 0) {
          setProductos(res);
        } else {
          setProductos([]);
        }
      } else {
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      return { msg: "no_conexion", status: false };
    }
  }, [isError]);

  useEffect(() => {
    getDatosIniciales();
  }, [getDatosIniciales]);

  const handleClickDownloadData = () => {
    if (productos.length > 0) {
      let datosParaExportar = [];

      datosParaExportar = productos.map(({ ...resto }) => resto);

      datosParaExportar = datosParaExportar.map(({ key, ...resto }) => resto);
      exportToExcel(datosParaExportar, "Reporte de productos billeteate ");
    }
  };

  const formateaCantidad = (cantidad: number) => {
    const totalCostoFormateado = (cantidad / 1).toLocaleString("de-DE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return totalCostoFormateado;
  };

  const columns: TableColumnsType<Productos> = [
    {
      title: "Fecha Registro",
      dataIndex: "Fecha_Registro",
      key: "Fecha_Registro",
      className: "letraPequenaVisor2",
      width: "105px",
    },
    {
      title: "Cod Sg",
      dataIndex: "Cod_Producto",
      key: "Cod_Producto",
      ...getColumnSearchPropsNewVisorReporte("Cod_Producto"),
      className: "letraPequenaVisor2",
      width: "70px",
    },
    {
      title: "Cod Mia",
      dataIndex: "Cod_MIA",
      key: "Cod_MIA",
      className: "letraPequenaVisor2",
      ...getColumnSearchPropsNewVisorReporte("Cod_MIA"),
      width: "100px",
    },
    {
      title: "Descripcion",
      dataIndex: "Descripcion",
      key: "Descripcion",
      className: "letraPequenaVisor2",
      ...getColumnSearchPropsNewVisorReporte("Descripcion"),
      width: "150px",
    },
    {
      title: "Codigo de barra",
      dataIndex: "Cod_Barra",
      key: "Cod_Barra",
      className: "letraPequenaVisor2",
      width: "110px",
    },
    {
      title: "Laboratorio",
      dataIndex: "laboratorio",
      key: "laboratorio",
      className: "letraPequenaVisor2",
      ...getColumnSearchPropsNewVisorReporte("laboratorio"),
      width: "170px",
    },
    {
      title: "Iva",
      dataIndex: "IVA",
      key: "IVA",
      className: "letraPequenaVisor2",
      width: "50px",
    },
    {
      title: "PVP",
      dataIndex: "PVP",
      key: "PVP",
      align: "right",
      className: "letraPequenaVisor2",
      render: (_, record) => (
        <span style={{ color: record.PVP === 0 ? "lightgray" : "black" }}>{formateaCantidad(record.PVP)}</span>
        // <span style={{ color: record.total === 0 ? "lightgray" : "black" }}>{record.total}</span>
      ),
    },
    {
      title: "PVF",
      dataIndex: "PVF",
      key: "PVF",
      align: "right",
      className: "letraPequenaVisor2",
      render: (_, record) => (
        <span style={{ color: record.PVF === 0 ? "lightgray" : "black" }}>{formateaCantidad(record.PVF)}</span>
        // <span style={{ color: record.total === 0 ? "lightgray" : "black" }}>{record.total}</span>
      ),
    },
    {
      title: "U_Medida",
      dataIndex: "U_Medida",
      key: "U_Medida",
      className: "letraPequenaVisor2",
    },
    {
      title: "Estado",
      dataIndex: "estado",
      key: "estado",
      className: "letraPequenaVisor2",
      width: "70px",
    },
    {
      title: "Observ.",
      dataIndex: "observacion",
      key: "observacion",
      className: "letraPequenaVisor2",
      width: "90px",
    },
    {
      title: "Rebate%",
      dataIndex: "rebate",
      key: "rebate",
      className: "letraPequenaVisor2",
      width: "70px",
      render: (_, record) => (
        <span style={{ color: record.rebate === 0 ? "lightgray" : "black" }}>{formateaCantidad(record.rebate)}</span>
      ),
    },
  ];
  return {
    columns,
    fechaInicio,
    fechaFin,
    setFechas,
    productos,
    setTipoMovimiento,
    tipoMovimiento,
    handleClickDownloadData,
  };
}
