import DescuentosDias from "pages/contentReportesGeneral/DescuentosDias";
import Productos from "pages/contentReportesGeneral/Productos";
import ProductosBilleteate from "pages/contentReportesGeneral/ProductosBilleteate";
import ProductosBilleteateEliminados from "pages/contentReportesGeneral/ReporteBilleteateEliminados";
import ReporteResumenBodega from "pages/contentReportesGeneral/ReporteResumenBodega";
import ReporteVentas from "pages/contentReportesGeneral/ReporteVentas";
import ReporteVentasCredito from "pages/contentReportesGeneral/ReporteVentasCredito";
import { ReporteVentasPorProducto } from "pages/contentReportesGeneral/ReporteVentasPorProducto";
import { FacturasPendientesGruposProveedores } from "pages/contentReportesGeneral/facturas-pendientes-grupos-proveedores";
import { Principal } from "pages/contentprincipal/Principal";
import { RouteObject } from "react-router-dom";

export const rutasReportes: RouteObject[] = [
  {
    path: "/reportes",
    element: <Principal />,
  },
  {
    path: "/reportes/ventas",
    element: <Principal />,
  },
  {
    path: "/reportes/ventas-detalladas",
    element: <ReporteVentas />,
  },
  {
    path: "/reportes/resumen-producto",
    element: <ReporteVentasPorProducto />,
  },

  { path: "/reportes/ventas-credito", element: <ReporteVentasCredito /> },
  {
    path: "/reportes/productos",
    element: <Productos />,
  },
  {
    path: "/reportes/productos-descuentos",
    element: <DescuentosDias />,
  },
  {
    path: "/reportes/proveedores/documentos-pendientes",
    element: <FacturasPendientesGruposProveedores />,
  },
  {
    path: "/reportes/productos_billeteate",
    element: <ProductosBilleteate />,
  },
  {
    path: "/reportes/ventas/resumen-bodega",
    element: <ReporteResumenBodega />,
  },

  {
    path: "/reportes/productos_billeteate_eliminados",
    element: <ProductosBilleteateEliminados />,
  },
];
