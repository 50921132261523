import { ColumnsType } from "antd/lib/table"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { optionsPOST,  url_interna } from "config/constants"
import { swalError } from "config/methods"
import moment from "moment"
import { useSearchTableNew } from "hooks/otros/useSearchTableNew"
import { UserContext } from "context/UserContext"
import ExcelJS from "exceljs"

export type ReporteBonificacion = {
  key: React.Key
  promocion: string
  comprar: number
  llevar: number
  acumulable: string
  bodega: string
  fecha: Date
  factura: string
  num_fact_canje: number
  identificacion_c: string
  cliente: string
  telefono: string
  ciudad: string
  calle: string
  fecha_nacimiento: Date
  tipo: string
  codigo: string
  producto: string
  cantidad: number
  cant_procesada: string
  balance: string
  unidades: number
  fracciones: number
  boni_unidades: number
  boni_fracciones: number
  linea_laboratorio: string
  Usuario: String
  pvp: number
  descuento: number
  costo: number
  subtotal_0: number
  subtotal_12: number
  impuesto: number
  total: number
  asume_promocion: string
  metodo_pago: string
  representante: string
  metodo: string
  valor_referencia: number
  porcentaje_laboratorio: number
  valor_laboratorio: number
  porcentaje_farmacia: number
  valor_farmacia: number
}

export function useReporteVentasMF() {
  const [DataBonificacion, setDataBonificacion] = useState<ReporteBonificacion[]>([])
  const [fechasSeleccionadas, setFechasSeleccionadas] = useState<[moment.Moment, moment.Moment]>()
  const { getColumnSearchPropsNew } = useSearchTableNew<ReporteBonificacion>()
  const [estado, setestado] = useState(false)
  const disabledDate = (current: any) => {
    // Si la fecha actual es mayor a la fecha seleccionada, deshabilita la fecha
    return current && current > moment().endOf("day")
  }
 const { userData } = useContext(UserContext);


  const columns: ColumnsType<ReporteBonificacion> = [
    { title: "Num", dataIndex: "key", className: "fontsize" },
    { title: "Promoción", dataIndex: "promocion", className: "fontsize", ...getColumnSearchPropsNew("promocion") },
    { title: "Comprar", dataIndex: "comprar", className: "fontsize", align: "center" },
    { title: "Llevar", dataIndex: "llevar", className: "fontsize", align: "center" },
    { title: "Acumulable", dataIndex: "acumulable", className: "fontsize" },
    { title: "Bodega", dataIndex: "bodega", className: "fontsize", width: "300px" },
    { title: "Fecha", dataIndex: "fecha", className: "fontsize" },
    { title: "Factura", dataIndex: "factura", className: "fontsize" },
    { title: "Factura Canje", dataIndex: "num_fact_canje", className: "fontsize" },
    { title: "Identificación", dataIndex: "identificacion_c", className: "fontsize" },
    { title: "Cliente", dataIndex: "cliente", className: "fontsize", ...getColumnSearchPropsNew("cliente") },
    { title: "Teléfono", dataIndex: "telefono", className: "fontsize" },
    { title: "Ciudad", dataIndex: "ciudad", className: "fontsize" },
    { title: "Calle", dataIndex: "calle", className: "fontsize" },
    { title: "Fecha Nacimiento", dataIndex: "fecha_nacimiento", className: "fontsize" },
    { title: "Tipo", dataIndex: "tipo", className: "fontsize" },
    { title: "Código", dataIndex: "codigo", className: "fontsize" },
    { title: "Producto", dataIndex: "producto", className: "fontsize", ...getColumnSearchPropsNew("producto") },
    { title: "Cantidad", dataIndex: "cantidad", className: "fontsize" },
    { title: "Cantidad Procesada", dataIndex: "cant_procesada", className: "fontsize", align: "right" },
    { title: "Balance", dataIndex: "balance", className: "fontsize" },
    { title: "Unidades", dataIndex: "unidades", className: "fontsize", align: "center" },
    { title: "fracciones", dataIndex: "fracciones", className: "fontsize", align: "center" },
    { title: "Boni Unidades", dataIndex: "boni_unidades", className: "fontsize", align: "center" },
    { title: "Boni Fracciones", dataIndex: "boni_fracciones", className: "fontsize", align: "center" },
    {
      title: "Linea Laboratorio",
      dataIndex: "linea_laboratorio",
      className: "fontsize",
      ...getColumnSearchPropsNew("linea_laboratorio"),
    },
    { title: "PVP", dataIndex: "pvp", className: "fontsize", align: "right" },
    { title: "Descuento", dataIndex: "descuento", className: "fontsize", align: "right" },
    { title: "Costo", dataIndex: "costo", className: "fontsize", align: "right" },
    { title: "Subtotal 0", dataIndex: "subtotal_0", className: "fontsize", align: "right" },
    { title: "Subtotal 15", dataIndex: "subtotal_12", className: "fontsize", align: "right" },
    { title: "Impuesto", dataIndex: "impuesto", className: "fontsize", align: "right" },
    { title: "Total", dataIndex: "total", className: "fontsize", align: "right" },
    {
      title: "Asume Promoción",
      dataIndex: "asume_promocion",
      className: "fontsize",
      ...getColumnSearchPropsNew("asume_promocion"),
    },
    { title: "Método de pago", dataIndex: "metodo_pago", className: "fontsize" },
    { title: "Representante", dataIndex: "representante", className: "fontsize" },
    { title: "Valor Referencia", dataIndex: "valor_referencia", className: "fontsize", align: "right" },
    { title: "Asume Laboratorio %", dataIndex: "porcentaje_laboratorio", className: "fontsize", align: "right" },
    { title: "Asume Laboratorio", dataIndex: "valor_laboratorio", className: "fontsize", align: "right" },
    { title: "Porcentaje Farmacia", dataIndex: "porcentaje_farmacia", className: "fontsize", align: "right" },
    { title: "Valor Farmacia", dataIndex: "valor_farmacia", className: "fontsize", align: "right" },
  ]

  const handleDateChange = (dates: any, dateStrings: any) => {
    setFechasSeleccionadas(dates)
  }

  const getHistorialBonificaciones = useCallback(async () => {
    try {
      setestado(false)
      const res = await fetch(
        `${url_interna}api/productos/historial-bonificaciones`,
       optionsPOST(
              JSON.stringify({
                fecha_inicio: fechasSeleccionadas ? fechasSeleccionadas[0].format("YYYY-MM-DD") : "",
                fecha_fin: fechasSeleccionadas ? fechasSeleccionadas[1].format("YYYY-MM-DD") : "",
              }),
              {
                "api-token": "apitoken",
              }
            )
      ).then((data) => data.json())
      //console.log("res", res)
      let newdata: any = []
      if (res) {
        setestado(true)
        newdata = Array.from(res).map((item: any, key: any) => ({
          key: key + 1,
          promocion: item.Promocion,
          comprar: item.Comprar,
          llevar: item.Llevar,
          acumulable: item.Acumulable,
          bodega: item.Descripcion,
          fecha: item.Fecha_Factura,
          factura: item.Factura,
          num_fact_canje: item.Numero_Factura_Canje,
          identificacion_c: item.Identificacion_Cliente,
          cliente: item.Cliente,
          telefono: item.Telefono,
          ciudad: item.ciudad,
          calle: item.Direccion,
          fecha_nacimiento: item.Fecha_Nacimiento,
          tipo: item.Tipo,
          codigo: item.Codigo,
          producto: item.Producto,
          cantidad: item.cantidad,
          cant_procesada: item.Cant_Procesada,
          balance: item.Balance,
          unidades: item.Unidades,
          fracciones: item.Fracciones,
          boni_unidades: item.Boni_Unidades,
          boni_fracciones: item.Boni_Fracciones,
          linea_laboratorio: item.Labotorio,
          Usuario: item.Usuario,
          pvp: item.PVP,
          descuento: item.Descuento,
          costo: item.Costo,
          subtotal_0: item.Subtotal_0,
          subtotal_12: item.Subtotal_15,
          impuesto: item.Impuesto,
          total: item.Total,
          asume_promocion: item.Asume_Promocion,
          metodo_pago: item.Metodo_de_Pago,
          representante: item.Representante,
          valor_referencia: item.Valor_Referencia,
          porcentaje_laboratorio: item.porcentaje_laboratorio,
          valor_laboratorio: item.asume_laboratorio,
          porcentaje_farmacia: item.asume_Farmacia,
          valor_farmacia: item.asume_Farmacia_porcentaje,
        }))
      }
      setDataBonificacion(newdata)
      setestado(true)
    } catch (error) {
      console.log(error)
    }
  }, [fechasSeleccionadas])

  useEffect(() => {
    getHistorialBonificaciones()
  }, [getHistorialBonificaciones])

       const handleDownload=async ()=>{
        try{
          const startRow = 11; // Iniciar desde la fila 11
          const startCol = 1;
          const workbook = new ExcelJS.Workbook()
              const worksheet = workbook.addWorksheet("Reporte Plan Apoyo")
              // Definir las columnas
              worksheet.mergeCells(`A1:U1`)
              const mergedCell1 = worksheet.getCell(`A1`)
              mergedCell1.value = "FARMACIAS SAN GREGORIO"
              mergedCell1.font = { bold: true, size: 12 } // Cambiar el tamaño de la fuente de la celda combinada
              mergedCell1.alignment = { horizontal: "center" }
  
              worksheet.mergeCells(`A2:U2`)
              const mergedCell2 = worksheet.getCell(`A2`)
              mergedCell2.value = "PAULO EMILIO MACIAS Y PEDRO ZAMBRANO BARCIA"
              mergedCell2.font = { bold: true, size: 12 } // Cambiar el tamaño de la fuente de la celda combinada
              mergedCell2.alignment = { horizontal: "center" }
  
              worksheet.mergeCells(`A3:U3`)
              const mergedCell3 = worksheet.getCell(`A3`)
              mergedCell3.value = "PORTOVIEJO-ECUADOR"
              mergedCell3.font = { bold: true, size: 12 } // Cambiar el tamaño de la fuente de la celda combinada
              mergedCell3.alignment = { horizontal: "center" }
  
              worksheet.mergeCells(`A4:U4`)
              const mergedCell4 = worksheet.getCell(`A4`)
              mergedCell4.value = "1391730780001"
              mergedCell4.font = { bold: true, size: 12 } // Cambiar el tamaño de la fuente de la celda combinada
              mergedCell4.alignment = { horizontal: "center" }
  
              worksheet.mergeCells(`A6:U6`)
              const mergedCell6 = worksheet.getCell(`A6`)
              mergedCell6.value = "REPORTE PLAN APOYO"
              mergedCell6.font = { bold: true, size: 12 } // Cambiar el tamaño de la fuente de la celda combinada
              mergedCell6.alignment = { horizontal: "center" }
  
              
              worksheet.mergeCells(`A8:B8`)
              const mergedCell8 = worksheet.getCell(`A8`)
              mergedCell8.value = "Fecha"
              mergedCell8.font = { bold: true, size: 12 } // Cambiar el tamaño de la fuente de la celda combinada
              mergedCell8.alignment = { horizontal: "left"}
              worksheet.mergeCells(`C8:E8`)
              const mergedCellB8 = worksheet.getCell(`C8`)
              mergedCellB8.value = (fechasSeleccionadas?.[0] ? fechasSeleccionadas[0].format('DD/MM/YYYY') : "")+' a '+(fechasSeleccionadas?.[1] ? fechasSeleccionadas[1]?.format('DD/MM/YYYY') : "Fecha no seleccionada");
              mergedCellB8.font = {  size: 12 } // Cambiar el tamaño de la fuente de la celda combinada
              mergedCellB8.alignment = { horizontal: "center"}
  
              worksheet.mergeCells(`A9:B9`)
              const mergedCell9 = worksheet.getCell(`A9`)
              mergedCell9.value = "Fecha de exportación"
              mergedCell9.font = { bold: true, size: 12 } // Cambiar el tamaño de la fuente de la celda combinada
              mergedCell9.alignment = { horizontal: "left"}
              worksheet.mergeCells(`C9:E9`)
              const mergedCellB9 = worksheet.getCell(`C9`)
              mergedCellB9.value = moment().format("DD/MM/YYYY hh:mm:ss");
              mergedCellB9.font = {  size: 12 } // Cambiar el tamaño de la fuente de la celda combinada
              mergedCellB9.alignment = { horizontal: "center"}
  
              worksheet.mergeCells(`G9`)
              const mergedCell10 = worksheet.getCell(`G9`)
              mergedCell10.value = "Usuario"
              mergedCell10.font = { bold: true, size: 12 } // Cambiar el tamaño de la fuente de la celda combinada
              mergedCell10.alignment = { horizontal: "center"}
              worksheet.mergeCells(`H9:I9`)
              const mergedCellB10 = worksheet.getCell(`H9`)
              mergedCellB10.value = userData?.usuario
              mergedCellB10.font = {  size: 12 } // Cambiar el tamaño de la fuente de la celda combinada
              mergedCellB10.alignment = { horizontal: "center"}
              
              const dataExport = DataBonificacion.map((item)=>{
                return{
                  "Promoción": item.promocion,
                  "Comprar": item.comprar,
                  "Llevar": item.llevar,
                  "Acumulable": item.acumulable,
                  "Bodega": item.bodega,
                  "Fecha": item.fecha,
                  "Factura": item.factura,
                  "Número Factura Canje": item.num_fact_canje,
                  "Identificación Cliente": item.identificacion_c,
                  "Cliente": item.cliente,
                  "Télefono": item.telefono,
                  "Ciudad": item.ciudad,
                  "Calle": item.calle,
                  "Fecha Nacimiento": item.fecha_nacimiento,
                  "Tipo": item.tipo,
                  "Codigo": item.codigo,
                  "Producto": item.producto,
                  "Cantidad": item.cantidad,
                  "Cantidad Procesada": item.cant_procesada,
                  "Balance": item.balance,
                  "Unidades": item.unidades,
                  "Fracciones": item.fracciones,
                  "Boni Unidades": item.boni_unidades,
                  "Boni Fracciones": item.boni_fracciones,
                  "Linea Laboratorio": item.linea_laboratorio,
                  "Usuario": item.Usuario,
                  "PVP": item.pvp,
                  "Descuento": item.descuento,
                  "Costo": item.costo,
                  "Subtotal 0": item.subtotal_0,
                  "Subtotal 12": item.subtotal_12,
                  "Impuesto": item.impuesto,
                  "Total": item.total,
                  "Asume Promocion": item.asume_promocion,
                  "Metodo Pago": item.metodo_pago,
                  "Representante": item.representante,
                  "Metodo": item.metodo,
                  "Valor Referencia": item.valor_referencia,
                  "% Asume": item.porcentaje_laboratorio,
                  "$ Asume": item.valor_laboratorio,
                  "% Farmacia": item.porcentaje_farmacia,
                  "$ Farmacia": item.valor_farmacia
                }
            })
  
            const columnHeaders = Object.keys(dataExport[0]).map((key) => ({
              key,
              header: key.toUpperCase(), // Puedes personalizar el nombre aquí
              width: key==='PROMOCIÓN'?'1200':'40'
            }));
  
            columnHeaders.forEach((col, index) => {
              worksheet.getCell(startRow, startCol + index).value = col.header;
              worksheet.getCell(startRow, startCol + index).font = { bold: true, color: { argb: "FFFFFF" } }; // Negrita para encabezados
              worksheet.getCell(startRow, startCol + index).fill={type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FFCD6155" /*  "E6E6B0AA" */ } }
            });
  
           dataExport.forEach((item2: any, index: number) => {
              const row = worksheet.getRow(9 + 3 + index)
              // Extraer los valores de `item2` en el mismo orden de las columnas definidas
              const rowValues = columnHeaders.map((col) => (item2[col.key] !== undefined ? item2[col.key] : 0.00)); // Aquí alineamos las claves con los valores
              row.values = rowValues // Colocar los valores en la fila
              //console.log(rowValues)
              row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
                // Si la celda está vacía, asignar 0
                cell.font = { size: 11 }
                let validarcolumnas=[26,27,28,29,30,31,32,33,38,40,42]
                if (validarcolumnas.includes(colNumber)) {
                    // Convertir a número si es un texto que representa un número
                    if (typeof cell.value === "string" && !isNaN(Number(cell.value))) {
                      cell.value = Number(cell.value) // Convertir texto a número
                    }
                  cell.alignment = { horizontal: "right" } // Alinear a la derecha
                  cell.numFmt = "$#,##0.00" // Formato de contabilidad, puedes ajustar según tus necesidades
                }
              })
      
              row.commit()
            })
                const buffer = await workbook.xlsx.writeBuffer()
                const blob = new Blob([buffer], { type: "application/octet-stream" })
                const url = window.URL.createObjectURL(blob)
            
                // Crear un enlace para descargar
                const a = document.createElement("a")
                a.href = url
                a.download = `Reporte_HistorialBonificaciones${moment().format("YYYYMMDD")}.xlsx`
                document.body.appendChild(a)
                a.click()
                document.body.removeChild(a)
                window.URL.revokeObjectURL(url)
            //console.log(columnHeaders)
          }catch(error){
            swalError({text:"Ocurrió un error vuelva a intentar"})
          }
        }
 /*   const handleDownload = () => {    
        const dataExport = DataBonificacion.map((item)=>{
            return{
              "Promoción": item.promocion,
              "Comprar": item.comprar,
              "Llevar": item.llevar,
              "Acumulable": item.acumulable,
              "Bodega": item.bodega,
              "Fecha": item.fecha,
              "Factura": item.factura,
              "Número Factura Canje": item.num_fact_canje,
              "Identificación Cliente": item.identificacion_c,
              "Cliente": item.cliente,
              "Télefono": item.telefono,
              "Ciudad": item.ciudad,
              "Calle": item.calle,
              "Fecha Nacimiento": item.fecha_nacimiento,
              "Tipo": item.tipo,
              "Codigo": item.codigo,
              "Producto": item.producto,
              "Cantidad": item.cantidad,
              "Cantidad Procesada": item.cant_procesada,
              "Balance": item.balance,
              "Unidades": item.unidades,
              "Fracciones": item.fracciones,
              "Boni Unidades": item.boni_unidades,
              "Boni Fracciones": item.boni_fracciones,
              "Linea Laboratorio": item.linea_laboratorio,
              "Usuario": item.Usuario,
              "PVP": item.pvp,
              "Descuento": item.descuento,
              "Costo": item.costo,
              "Subtotal 0": item.subtotal_0,
              "Subtotal 12": item.subtotal_12,
              "Impuesto": item.impuesto,
              "Total": item.total,
              "Asume Promocion": item.asume_promocion,
              "Metodo Pago": item.metodo_pago,
              "Representante": item.representante,
              "Metodo": item.metodo,
              "Valor Referencia": item.valor_referencia,
              "% Asume": item.porcentaje_laboratorio,
              "$ Asume": item.valor_laboratorio,
              "% Farmacia": item.porcentaje_farmacia,
              "$ Farmacia": item.valor_farmacia
              
            }
        })
        exportToExcel(dataExport, `Reporte_HistorialBonificaciones${moment().format("DDMMYYYY")}`)
      } */

  return {
    columns,
    DataBonificacion,
    fechasSeleccionadas,
    handleDateChange,
    estado,
    disabledDate,
    handleDownload
  }
}
