import { Button, Card, Col, Row, Select, Table } from 'antd';
import { ButtonExcel } from 'components/otros/buttons';
import useMinimosBodega from 'hooks/inventarios/logistica/useMinimosBodega'
import { useLayout } from 'hooks/otros/useLayout';
import React from 'react'

const breadcrumbs = [
  { Label: "Inventarios", Url: "inventarios" },
  { Label: "Logistica", Url: "/inventarios/logistica" },
  { Label: "Mínimos" },
]
const openKeys = [breadcrumbs[0].Label, `${breadcrumbs[0].Label}/${breadcrumbs[1].Label}`]
const selectKeySider = `${breadcrumbs[1].Label}/${breadcrumbs[2].Label}`

export default function MinimosBodega() {
  useLayout(breadcrumbs, openKeys, selectKeySider)
  const {  columns,
    filteredData,
    handleClickDownloadData,
    datosPeriodos,
    setBodega,
    bodega,
    dataEstado,
    estadoBotones,
    handleChangeButton,
    calcularTotales,}=useMinimosBodega();

  return (
    <Row style={{ padding: "16px", marginRight: "0px", backgroundColor: "#fff" }} gutter={[16, 16]}>
      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <Select
          placeholder='Selecione una bodega'
          style={{ width: 250, marginRight: "10px", marginTop: "0px" }}
          onChange={(value) => setBodega(value)}
          options={datosPeriodos}
        />

        <Button
          type='primary'
          style={{ marginLeft: "5px" }}
          //icon={<PoweroffOutlined />}
          loading={estadoBotones}
          disabled={bodega === undefined}
          onClick={handleChangeButton}
        >
          Generar reporte
        </Button>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <Row gutter={[10, 10]} style={{ marginBottom: "20px", display: "flex", justifyContent: "flex-end" }}>
          <Col xs={24} md={10}>
            {dataEstado && <ButtonExcel  buttonProps={{ onClick: handleClickDownloadData }}  />}
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={24} >
        {dataEstado && (
          <Card  >
             <Table
            style={{width:'100%'}}
              columns={columns}
              dataSource={filteredData}
              scroll={{ x: true }}
              size='small'
              pagination={{ pageSize: 10, hideOnSinglePage: true, position: ["bottomCenter"] }}
              summary={() => {
                const { totalItems, unid } = calcularTotales();
                return (
                  <Table.Summary fixed>
                    <Table.Summary.Row style={{ backgroundColor: "#f0f0f0" }}>
                      <Table.Summary.Cell index={0}>Total items:</Table.Summary.Cell>
                      <Table.Summary.Cell index={1}>{`${totalItems}`}</Table.Summary.Cell>
                      <Table.Summary.Cell index={2}></Table.Summary.Cell>
                      <Table.Summary.Cell index={3}></Table.Summary.Cell>
                      <Table.Summary.Cell index={4}></Table.Summary.Cell>
{/*                       <Table.Summary.Cell index={5}></Table.Summary.Cell>
                      <Table.Summary.Cell index={6}></Table.Summary.Cell> */}
                      <Table.Summary.Cell align='right' index={5}>
                        Total de Mínimos:
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align='right' index={6}>
                        {`${unid}`}
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                );
              }}
            />
          </Card>
        )}
      </Col>
     
    </Row>
  )
}
