import { Col, Row } from "antd";
import { useQRBodega } from "hooks/rrhh/qrBodega/useQRBodega";
import QRCode from "react-qr-code"; // Usando react-qr-code
import Card from "antd/lib/card/Card";

export function QRBodegas() {
  const { datosInicial } = useQRBodega();

  return (
    <Card >
      <Row style={{ padding: "16px", marginRight: "0px", backgroundColor: "#fff" }} gutter={[16, 16]}>
        {datosInicial.map((bodega, index) => (
          <Col  style={{alignContent:"center"}} key={index} xs={24} sm={24} md={24} lg={24} xl={24}>
            <div style={{display:"flex", flexDirection: "column",alignItems:"center"}}>
              <h3>Farmacia: {bodega.Descripcion}</h3>
              <h4>Inicio de sesión SHERLOCK</h4>
              {/* Generar y mostrar el código QR con react-qr-code */}
              <QRCode value={bodega.LlaveQR.toString()} size={250} />
              <h3>{bodega.LlaveQR}</h3>
            </div>
          </Col>
        ))}
      </Row>
    </Card>
  );
}

export default QRBodegas;
