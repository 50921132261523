import { Col, Row, Table, Card } from "antd";
import "moment/locale/es";
import { ButtonExcel } from "components/otros/buttons";
import { useVT } from "virtualizedtableforantd4";
import { useProductosBilleteateEliminados } from "hooks/reportesGeneral/useProductosBilleteateEliminados";

export function ProductosBilleteateEliminados() {
  const { columns, productos, handleClickDownloadData } = useProductosBilleteateEliminados();

  const [vt] = useVT(() => ({ scroll: { y: "55vh" } }), []);
  return (
    <Row style={{ padding: "16px", marginRight: "0px", backgroundColor: "#fff" }} gutter={[16, 16]}>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Row gutter={[10, 10]} style={{ marginBottom: "16px", display: "flex", justifyContent: "flex-end" }}>
          <Col>{productos && <ButtonExcel buttonProps={{ onClick: handleClickDownloadData }} />}</Col>
        </Row>

        <Card>
          <Table
            components={vt}
            columns={columns}
            dataSource={productos}
            scroll={{ x: 900, y: 450 }}
            size='small'
            pagination={false}
          />
        </Card>
      </Col>
    </Row>
  );
}

export default ProductosBilleteateEliminados;
