import { Layout as AntdLayout, PageHeader, Dropdown, Menu, Button, Avatar, Typography, Breadcrumb, Row, Space, Drawer } from "antd";
import { Outlet, Link, useNavigate, useLocation } from "react-router-dom";
import { MdAccountCircle } from "react-icons/md";
import { FaBars } from "react-icons/fa";
import { ArrowLeftOutlined, CloseOutlined, MenuOutlined } from "@ant-design/icons"; // Importamos MenuOutlined para el botón
import { LogoWhite } from "components/svg/LogoWhite";
import { ContextLayout } from "context/LayoutContext";
import { useAuth } from "hooks/otros/useAuth";
import { useContext, useEffect, useState } from "react";
import { useScreenSize } from "hooks/otros/useScreenSize";
import styles from "layouts/Layout.module.css";
import type { MenuProps } from "antd";
import { LeaveIcon } from "components/svg/Icons";
import type { menuSistema } from "types.d";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { ICONS } from "pages/contentprincipal/Principal";
import { UserContext } from "context/UserContext";
import { NoImage } from "components/svg/Menu";

const { Sider, Content } = AntdLayout;
const { Text } = Typography;

const propsSvg = { fill: "#000", width: "15px", height: "15px" };

const renderItemsSider = (menu: menuSistema[]): ItemType[] => {
  return menu.map(({ key, label, enlace, children, nivel }) => ({
    key: key,
    label: enlace ? (children ? label : <Link to={enlace}>{label}</Link>) : label,
    children: children ? renderItemsSider(children) : undefined,
    icon: nivel === 0 && (ICONS[label as keyof typeof ICONS] ?? (() => <NoImage {...propsSvg} />))(propsSvg),
  }));
};

const getOpenedKeys = (openKeys: Array<string>, menu: menuSistema[]) => {
  if (menu[0].children) {
    openKeys.push(menu[0].key);
    getOpenedKeys(openKeys, menu[0].children);
  }
};

const getSelectedKey = (selectKey: string[], menu: menuSistema[]) => {
  if (menu[0].children) {
    getSelectedKey(selectKey, menu[0].children);
  } else {
    selectKey.push(menu[0].key);
  }
};

export function Layout() {
  const { userData: dataUser } = useContext(UserContext);
  const { logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { width } = useScreenSize();

  const isWeb = width > 992;

  const [openKeys, setOpenKeys] = useState<Array<string>>([]);
  const [selectKeys, setSelectKeys] = useState<Array<string>>([]);
  const [showBack, setShowBack] = useState(false);
  const [breadCrumbs, setBreadCrumbs] = useState<{ Label: string | JSX.Element | JSX.Element[]; Url?: string }[]>([]);
  const [showDrawer, setShowDrawer] = useState(false);
  const [isSiderVisible, setIsSiderVisible] = useState(false); // Controla la visibilidad del Sider

  useEffect(() => {
    if (!dataUser) return;
    let openKeys: Array<string> = [];
    getOpenedKeys(openKeys, dataUser.menu);
    setOpenKeys((state) => (state ? state : openKeys));
    let selectKey: Array<string> = [];
    getSelectedKey(selectKey, dataUser.menu);
    setSelectKeys((state) => (state ? state : selectKey));
  }, [dataUser]);

  useEffect(() => {
    location.pathname === "/" ? setShowBack(false) : setShowBack(true);
    setShowDrawer(false);
  }, [location, breadCrumbs]);

  const items = [
    { key: "avatar", label: <div style={{ width: "100%", textAlign: "center" }}><Avatar style={{ backgroundColor: "var(--primary)" }}>{dataUser?.iniciales}</Avatar></div> },
    { key: dataUser?.usuario || 0, label: <div style={{ width: "100%", textAlign: "center" }}><Text>{dataUser?.nombre}</Text></div> },
    {
      key: "Cerrar Sesion",
      label: <Space style={{ display: "flex", justifyContent: "space-between" }}><Text>Cerrar Sesión</Text><LeaveIcon width="1.5em" fill="var(--primary)" /></Space>,
      onClick: () => {
        localStorage.removeItem("token");
        navigate("/login");
        logout();
      },
    },
  ];

  const handleOpenChange: MenuProps["onOpenChange"] = (keys) => setOpenKeys(keys);

  // Función para alternar la visibilidad del Sider
  const toggleSider = () => {
    setIsSiderVisible(!isSiderVisible);
  };

  return (
    <>
      <PageHeader
        
        title={
          <Space>
            {/* Botón para alternar el Sider */}
            {isWeb && (
              <Button
              icon={<FaBars style={{ color: "var(--primary)" }} />}

                onClick={toggleSider}
                style={{ color: "#fff" }}
              />
            )}
            <LogoWhite width="100%" style={{ padding: "0", margin: "0" }} />
          </Space>
        }
        style={{ position: "fixed", width: "100%", zIndex: 1500 }}
        ghost={false}
        extra={
          isWeb ? (
            <Dropdown key="more" menu={{ items, style: { width: "200px" } }} placement="bottomRight">
              <Space>
                <Button type="text" icon={<MdAccountCircle className={styles.iconUser} />} />
                <Text style={{ color: "#fff" }}>{dataUser?.usuario}</Text>
              </Space>
            </Dropdown>
          ) : (
            <>
              <Button
                icon={<FaBars style={{ color: "var(--primary)" }} />}
                style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                onClick={() => setShowDrawer(true)}
              />
              <Drawer
                open={showDrawer}
                placement="left"
                onClose={() => setShowDrawer(false)}
                closeIcon={<></>}
                extra={<Button icon={<CloseOutlined />} onClick={() => setShowDrawer(false)} />}
                footer={
                  <Button
                    type="text"
                    style={{ width: "100%", display: "flex", justifyContent: "space-between" }}
                    onClick={() => {
                      localStorage.removeItem("token");
                      navigate("/login");
                      logout();
                    }}
                  >
                    <Text>Cerrar Sesión</Text>
                    <LeaveIcon width="1.5em" fill="var(--primary)" />
                  </Button>
                }
              >
                <Space direction="vertical" style={{ width: "100%", textAlign: "center" }}>
                  <div style={{ width: "100%", textAlign: "center" }}>
                    <Avatar style={{ backgroundColor: "var(--primary)" }}>{dataUser?.iniciales}</Avatar>
                  </div>
                  <div style={{ width: "100%", textAlign: "center" }}>
                    <Text>{dataUser?.nombre}</Text>
                  </div>
                  <Menu
                    mode="inline"
                    selectedKeys={selectKeys}
                    openKeys={openKeys}
                    items={dataUser ? renderItemsSider(dataUser.menu) : undefined}
                    onOpenChange={handleOpenChange}
                  />
                </Space>
              </Drawer>
            </>
          )
        }
      />
      <AntdLayout>
        {/* Sider controlado por el botón */}
        {isWeb && (
          <Sider
            className={styles.sider}
            theme="light"
            style={{
              transform: isSiderVisible ? "translateX(0)" : "translateX(-250px)", // Desliza el Sider
              transition: "transform 0.3s ease-in-out",
              position: "fixed",
              left: 0,
              top: 61.84,
              height: "calc(100vh - 60px)",
              width: 250,
              zIndex: 1000,
            }}
          >
            <Menu
              mode="inline"
              selectedKeys={selectKeys}
              openKeys={openKeys}
              items={dataUser ? renderItemsSider(dataUser.menu) : undefined}
              onOpenChange={handleOpenChange}
            />
          </Sider>
        )}
        <AntdLayout
          className={styles.ContentContentPage}
          style={{
            marginLeft: isWeb && isSiderVisible ? 250 : 0, // Ajusta el margen dinámicamente
            transition: "margin-left 0.3s ease-in-out", // Animación suave
          }}
        >
          <Content className={styles.ContentPage}>
            <Row style={{ backgroundColor: "#fff" }}>
              <Breadcrumb className={styles.breadcrumbScroll}>
                {breadCrumbs.map(({ Label, Url }, index) => (
                  Url ? (
                    <Breadcrumb.Item key={index}>
                      <Link to={Url ?? "/"}>{Label}</Link>
                    </Breadcrumb.Item>
                  ) : (
                    <Breadcrumb.Item key={index}>{Label}</Breadcrumb.Item>
                  )
                ))}
              </Breadcrumb>
            </Row>
            <ContextLayout
              setOpenKeys={setOpenKeys}
              setSelectedKeys={setSelectKeys}
              setShowBack={setShowBack}
              setBreadCrumbs={setBreadCrumbs}
            >
              <Outlet />
            </ContextLayout>
          </Content>
        </AntdLayout>
      </AntdLayout>
    </>
  );
}