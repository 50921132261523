import { Moment } from "moment";
import { useCallback, useEffect, useState } from "react";
import { optionsGET, url_interna } from "../../config/constants";

import { useVerifyResponse } from "hooks/otros/useVerifyReponse";

import { TableColumnsType } from "antd";

import { useLayout } from "hooks/otros/useLayout";

import { useSearchTableNew } from "hooks/otros/useSearchTableNew";
import { useExport } from "hooks/otros/useExport";

type Productos = {
  key: React.Key;
  Cod_Producto: number;
  Cod_Mia: string;
  Descripcion: string;
  fecha_eliminacion: string;
  Nombres_Rol: string;
  Fecha_inicio: string;
  Valor_Caja: number;
  Xventa: number;
};

export interface DatosPeriodo {
  label: string;
  value: number;
}
const breadcrumbs = [{ Label: "Reportes", Url: "/productos-billiteate-eliminados" }, { Label: "Billeteate Eliminados" }];

const openKeys = [breadcrumbs[0].Label];
const selectKeySider = `${breadcrumbs[0].Label}/${breadcrumbs[1].Label}`;

export function useProductosBilleteateEliminados() {
  useLayout(breadcrumbs, openKeys, selectKeySider);

  const [[fechaInicio, fechaFin], setFechas] = useState<[Moment | null, Moment | null]>([null, null]);

  const [tipoMovimiento, setTipoMovimiento] = useState<number>();
  const { exportToExcel } = useExport();

  const [productos, setProductos] = useState<Productos[]>([]);
  const { getColumnSearchPropsNew: getColumnSearchPropsNewVisorReporte } = useSearchTableNew<Productos>();

  const { isError } = useVerifyResponse();

  const getDatosIniciales = useCallback(async () => {
    setProductos([]);
    try {
      const respuesta = await fetch(url_interna + "api/reportes/productos_billeteate_eliminados", optionsGET).then((data) =>
        data.json()
      );

      if (respuesta.msg) {
        const res: Productos[] = respuesta.data;

        if (res.length > 0) {
          setProductos(res);
        } else {
          setProductos([]);
        }
      } else {
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      return { msg: "no_conexion", status: false };
    }
  }, [isError]);

  useEffect(() => {
    getDatosIniciales();
  }, [getDatosIniciales]);

  const handleClickDownloadData = () => {
    if (productos.length > 0) {
      let datosParaExportar = [];

      datosParaExportar = productos.map(({ ...resto }) => resto);

      datosParaExportar = datosParaExportar.map(({ key, ...resto }) => resto);
      exportToExcel(datosParaExportar, "Reporte de productos billeteate ");
    }
  };

  const formateaCantidad = (cantidad: number) => {
    const totalCostoFormateado = (cantidad / 1).toLocaleString("de-DE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return totalCostoFormateado;
  };

  const columns: TableColumnsType<Productos> = [
    {
      title: "Cod_Producto",
      dataIndex: "Cod_Producto",
      key: "Cod_Producto",
      ...getColumnSearchPropsNewVisorReporte("Cod_Producto"),
      className: "letraPequenaVisor2",
      width: "120px",
    },
    {
      title: "Cod_MIA",
      dataIndex: "Cod_MIA",
      key: "Cod_MIA",
      ...getColumnSearchPropsNewVisorReporte("Cod_Mia"),
      className: "letraPequenaVisor2",
      width: "120px",
    },
    {
      title: "Descripcion",
      dataIndex: "Descripcion",
      key: "Descripcion",
      className: "letraPequenaVisor2",
      ...getColumnSearchPropsNewVisorReporte("Descripcion"),
      width: "200px",
    },
    {
      title: "Fecha Eliminación",
      dataIndex: "fecha_eliminacion",
      key: "fecha_eliminacion",
      className: "letraPequenaVisor2",
      width: "150px",
    },
    {
      title: "Usuario",
      dataIndex: "Nombres_Rol",
      key: "Nombres_Rol",
      className: "letraPequenaVisor2",
      width: "160px",
    },
    {
      title: "Fecha Inicio",
      dataIndex: "fecha_inicio",
      key: "fecha_inicio",
      className: "letraPequenaVisor2",
      width: "170px",
    },
    {
      title: "Valor Venta",
      dataIndex: "Valor_Caja",
      key: "Valor_Caja",
      className: "letraPequenaVisor2",
      width: "50px",
      render: (_, record) => (
        <span style={{ color: record.Valor_Caja === 0 ? "lightgray" : "black" }}>{formateaCantidad(record.Valor_Caja)}</span>
        // <span style={{ color: record.total === 0 ? "lightgray" : "black" }}>{record.total}</span>
      ),
    },
    {
      title: "%Venta",
      dataIndex: "Xventa",
      key: "Xventa",
      className: "letraPequenaVisor2",
      width: "50px",
      render: (_, record) => (
        <span style={{ color: record.Xventa === 0 ? "lightgray" : "black" }}>{formateaCantidad(record.Xventa)}</span>
        // <span style={{ color: record.total === 0 ? "lightgray" : "black" }}>{record.total}</span>
      ),
    },
  ];
  return {
    columns,
    fechaInicio,
    fechaFin,
    setFechas,
    productos,
    setTipoMovimiento,
    tipoMovimiento,
    handleClickDownloadData,
  };
}
