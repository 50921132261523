import { Button, Tooltip } from "antd"
import { ColumnsType } from "antd/lib/table"
import { url } from "config/constants"
import { optionsGet, optionsPost, swalQuestion, swalSuccess } from "config/methods"
import React, { useCallback, useEffect, useState } from "react"
import { EyeOutlined } from "@ant-design/icons"
import moment from "moment"
import { useSearchTable } from "hooks/otros/useSearchTable"
import { CheckboxChangeEvent } from "antd/lib/checkbox"


export interface DataTypeReembolsos {
  key: React.Key
  Fecha: Date
  Justificacion: string
  usuario_solicita: string
  usuario_registra: string
  valor: number
}

export interface DataTypeReembolsoDet {
  key: React.Key
  Fecha: Date
  Ruc: string
  Razon_Social: string
  Detalle: string
  Tipo_Gasto: string
  Total: number
}

export function useCrearLiquidacion() {
  const [Data, setData] = useState<DataTypeReembolsos[]>()
  const [DataDet, setDataDet] = useState<DataTypeReembolsoDet[]>()
  const [DataRIva, setDataRIva] = useState([{ label: "Seleccione el Porcentaje", value: "" , porcentaje:'', cuenta:'' }])
  const [DataRFuente, setDataRFuente] = useState([{ label: "Seleccione el Porcentaje", value: "", porcentaje:'', cuenta:''}])
  const [DataGastos, setDataGastos] = useState([{ label: "Seleccione una Gastos", value: "" }])
  const [Fecha, setFecha] = useState<moment.Moment>(moment())
  const [openModal, setOpenModal] = useState(false)
  const [Estado, setEstado] = useState(true)
  const [CuentaRIva, setCuentaRIva] = useState("")
  const [CuentaRFuente, setCuentaRFuente] = useState("")
  const [CuentaGasto, setCuentaGasto] = useState("")
  const [PorcentajeIva, setPorcentajeIva] = useState("")
  const [PorcentajeFuente, setPorcentajeFuente] = useState("")
  const [Reembolso, setReembolso] = useState(false)
  const [RetencionIva, setRetencionIva] = useState(false)
  const [RetencionFuente, setRetencionFuente] = useState(false)
  const [Capacitacion, setCapacitacion] = useState(false)
  const { getColumnSearchProps } = useSearchTable<DataTypeReembolsos>()
  const [FechaReembolso, setFechaReembolso] = useState<Date>()
  const [Usuario_Solicita, setUsuario_Solicita] = useState<String>()
  const [showAlert, setshowAlert] = useState(false)
  const [Message, setMessage] = useState("")
  const [Cod_Reembolso, setCod_Reembolso] = useState("")
  const [Cod_ConceptoIVA, setCod_ConceptoIVA]=useState("")
  const [Cod_ConceptoFuente, setCod_ConceptoFuente]=useState("")
  const disabledDate = (current: any) => {
    // Si la fecha actual es mayor a la fecha seleccionada, deshabilita la fecha
    return current && current > moment().endOf("day")
  }

  const handleDateChange = (dates: any, dateStrings: any) => {
    setFecha(dateStrings)
  }

  const getReembolsosPendientes = useCallback(async () => {
    try {
      if (Estado === true) {
        handleDateChange(Fecha.format("YYYY/MM/DD"), Fecha.format("YYYY/MM/DD"))
        setEstado(false)
      }
      // setDataEstado(false);
      const res = await fetch(`${url}fapifsg-pr/reembolsos/listar-reembolsos`, optionsPost({ Fecha: Fecha })).then((data) =>
        data.json()
      )
      //console.log(res)
      let newdata: any = []
      if (res) {
        //setDataEstado(true);
        newdata = Array.from(res).map((item: any, key: any) => ({
          key: item.Cod_Reembolso,
          Fecha: item.Fecha,
          Justificacion: item.Justificacion,
          usuario_solicita: item.Solicita,
          usuario_registra: item.Usuario_Registra,
          valor: item.Valor_Reembolso.toFixed(2),
        }))
      }
      setData(newdata)
      //setDataEstado(true);
    } catch (error) {
      console.log(error)
    }
  }, [Fecha, Estado])

  const columns: ColumnsType<DataTypeReembolsos> = [
    {
      title: "Fecha",
      dataIndex: "Fecha",
      className: "nombre-column",
      sorter: (a, b) => moment(a.Fecha,"DD/MM/YYYY").toDate().getTime() - moment(b.Fecha, "DD/MM/YYYY").toDate().getTime(),
    },
    {
      title: "Justificación",
      dataIndex: "Justificacion",
      ...getColumnSearchProps("Justificacion"),
    },
    {
      title: "Usuario Solicita",
      dataIndex: "usuario_solicita",
      ...getColumnSearchProps("usuario_solicita"),
    },
    {
      title: "Usuario Registra",
      dataIndex: "usuario_registra",
      ...getColumnSearchProps("usuario_registra"),
    },
    {
      title: "Valor",
      dataIndex: "valor",
      align: "right",
      ...getColumnSearchProps("valor"),
    },
    {
      title: "",
      //dataIndex: "valor",
      render: (data) => {
        return (
          <>
            {" "}
            <Tooltip title="Ver Liquidación">
              <Button
                onClick={() => {
                  Btn_ver(data)
                }}
              >
                <EyeOutlined />
              </Button>
            </Tooltip>
          </>
        )
      },
    },
  ]

  const columnsDet: ColumnsType<DataTypeReembolsoDet> = [
    {
      title: "Fecha",
      dataIndex: "Fecha",
      className: "nombre-column",
      render: (Fecha) => {
        return moment(Fecha).format("DD/MM/YYYY")
      },
    },
    {
      title: "Ruc",
      dataIndex: "Ruc",
    },
    {
      title: "Razon Social",
      dataIndex: "Razon_Social",
    },
    {
      title: "Detalle",
      dataIndex: "Detalle",
    },
    {
      title: "Tipo Gasto",
      dataIndex: "Tipo_Gasto",
    },
    {
      title: "Total",
      dataIndex: "Total",
      align: "right",
      render: (total) => {
        return total.toFixed(2)
      },
    },
  ]

  const getReembolsoDet = async (Cod_Reembolso: string) => {
    try {
      const res = await fetch(
        `${url}fapifsg-pr/reembolsos/detalle-reembolsos`,
        optionsPost({ Cod_Reembolso: Cod_Reembolso })
      ).then((data) => data.json())
      //console.log(res)

      let newdata: any = []
      if (res) {
        //setDataEstado(true);
        newdata = Array.from(res).map((item: any, key: any) => ({
          key: key,
          Fecha: item.Fecha,
          Ruc: item.Ruc,
          Razon_Social: item.Razon_Social,
          Detalle: item.Detalle,
          Tipo_Gasto: item.Tipo_Gasto,
          Total: item.Total,
        }))
      }
      setDataDet(newdata)
    } catch (error) {
      console.log(error)
    }
  }

  const Btn_ver = (data: DataTypeReembolsos) => {
    setFechaReembolso(data.Fecha)
    setOpenModal(true)
    setUsuario_Solicita(data.usuario_solicita)
    getReembolsoDet(data.key.toString())
    getdataCuentaRIva()
    getdataCuentaRFuente()
    getdataCuentaGastos()
    setCapacitacion(false)
    setReembolso(false)
    setRetencionFuente(false)
    setRetencionIva(false)
    setPorcentajeFuente("")
    setPorcentajeIva("")
    setCuentaRIva("")
    setCuentaRFuente("")
    setCuentaGasto("")
    setMessage("")
    setshowAlert(false)
    setCod_Reembolso(data.key.toString())
    setCod_ConceptoIVA("")
    setCod_ConceptoFuente("")
  }

  useEffect(() => {
    getReembolsosPendientes()
  }, [getReembolsosPendientes])

  const getdataCuentaRIva = async () => {
    try {
      const res = await fetch(`${url}fapifsg-pr/reembolsos/listar-cuentas-riva`, optionsGet()).then((data) => data.json())
      let newget = [{ value: "", label: "Seleccione un Porcentaje" ,  porcentaje:'', cuenta:''}]
      if (res) {
        res.forEach((item: any) => {
          let add = {
            value: item.Cod_Concepto,
            label: item.nombre,
            porcentaje: item.porcentaje,
            cuenta: item.Cuenta
          }
          newget = [...newget, add]
        })
      }
      setDataRIva(newget)
    } catch (error) {
      console.log(error)
    }
  }

  const getdataCuentaRFuente = async () => {
    try {
      const res = await fetch(`${url}fapifsg-pr/reembolsos/listar-cuentas-rfuente`, optionsGet()).then((data) => data.json())

      let newget = [{ value: "", label: "Seleccione un Porcentaje", porcentaje:'', cuenta:'' }]
      if (res) {
        res.forEach((item: any) => {
          let add = {
            value: item.Cod_Concepto,
            label: item.nombre,
            porcentaje: item.porcentaje,
            cuenta: item.Cuenta
          }
          newget = [...newget, add]
        })
      }
      setDataRFuente(newget)
    } catch (error) {
      console.log(error)
    }
  }

  const getdataCuentaGastos = async () => {
    try {
      const res = await fetch(`${url}fapifsg-pr/reembolsos/listar-cuentas-gastos`, optionsGet()).then((data) => data.json())

      let newget = [{ value: "", label: "Seleccione una Cuenta" }]
      if (res) {
        res.forEach((item: any) => {
          let add = {
            value: item.Cod_Cuenta,
            label: item.Cod_Cuenta + "  " + item.Nombre,
          }
          newget = [...newget, add]
        })
      }
      setDataGastos(newget)
    } catch (error) {
      console.log(error)
    }
  }

  const onChangeCuentaRIva = (value: string) => {
    setshowAlert(false)
    setCod_ConceptoIVA(value)
    let data=DataRIva.filter((item)=>item.value===value)
    //console.log('data[0].porcentajeiva', data[0].porcentaje)
    setCuentaRIva(data[0].cuenta)
    setPorcentajeIva(data[0].porcentaje)
  }

  const onChangeCuentaRFuente = (value: string) => {
    setshowAlert(false)
    setCod_ConceptoFuente(value)
    let data=DataRFuente.filter((item)=>item.value===value)
    //console.log('data[0].porcentaje', data[0].porcentaje)
    setCuentaRFuente(data[0].cuenta)
    setPorcentajeFuente(data[0].porcentaje)
  }

  const onChangeCuentaGastos = (value: string) => {
    setshowAlert(false)
    setCuentaGasto(value)
  }

/*   const onChangePorcentajeIva = (e: any) => {
    setshowAlert(false)
    if (/^[0-9]{0,3}$/.test(e.target.value)) {
      setPorcentajeIva(e.target.value)
    }
  } */

/*   const onChangePorcentajeFuente = (e: any) => {
    setshowAlert(false)
    if (/^[0-9]{0,3}$/.test(e.target.value)) {
      setPorcentajeFuente(e.target.value)
    }
  } */

  const onChangeReembolso = (e: CheckboxChangeEvent) => {
    setshowAlert(false)
    setReembolso(e.target.checked)
    if (e.target.checked === true) {
      setCuentaGasto("")
    }
  }

  const onChangeRetencionIva = (e: CheckboxChangeEvent) => {
    setshowAlert(false)
    setRetencionIva(e.target.checked)
    if (e.target.checked === false) {
      setCuentaRIva("")
      setPorcentajeIva("")
      setCod_ConceptoIVA("")
    }
  }

  const onChangeRetencionFuente = (e: CheckboxChangeEvent) => {
    setshowAlert(false)
    setRetencionFuente(e.target.checked)
    if (e.target.checked === false) {
      setCuentaRFuente("")
      setPorcentajeFuente("")
      setCod_ConceptoFuente("")
    }
  }

  const onChangeCapacitacion = (e: CheckboxChangeEvent) => {
    setshowAlert(false)
    setCapacitacion(e.target.checked)
  }

  const btn_Guardar = async () => {
    try {
      setshowAlert(false)

      let message = ""
      if (RetencionIva === true) {
        if (Cod_ConceptoIVA === "" ) {
          setshowAlert(true)
          message = message + " Retencion Iva"
        }
      } else {
        setCuentaRIva("")
        setPorcentajeIva("")
      }

      if (RetencionFuente === true) {
        //console.log(PorcentajeFuente)
        if (
          Cod_ConceptoFuente === ""
        ) {
          setshowAlert(true)
          message = message + " Retencion Fuente"
        }
      } else {
        setCuentaRFuente("")
        setPorcentajeFuente("")
      }

      if (Reembolso === false || Capacitacion === true) {
        if (CuentaGasto === "") {
          setshowAlert(true)
          message += " Cuenta Gastos"
        }
      } else {
        setCuentaGasto("")
      }

      if (message !== "") {
        //console.log(message)
        setshowAlert(true)
        setMessage("Faltan datos de " + message)
        return
      }

      setOpenModal(false)
      swalQuestion({
        title: "¿Esta seguro de Guardar la liquidación?",
        confirmButtonText: "Si",
        denyButtonText: `No`,
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          const res = await fetch(
            `${url}fapifsg-pr/reembolsos/crear-reembolso-liquidacion`,
            optionsPost({
              Cod_Reembolso: Cod_Reembolso,
              Tiene_Reembolso: Reembolso,
              Tiene_Retencion: RetencionFuente,
              cod_cuenta_gasto: CuentaGasto,
              retiene_iva: RetencionIva,
              capacitacion: Capacitacion,
              retencion_fuente: PorcentajeFuente,
              retencion_iva: PorcentajeIva,
              cod_cuenta_rfuente: CuentaRFuente,
              cod_cuenta_riva: CuentaRIva,
              cod_Concepto_iva: Cod_ConceptoIVA,
              cod_Concepto_fuente:Cod_ConceptoFuente
            })
          ).then((data) => data.json())
          if (res.msg === "ok") {
            swalSuccess({ text: "Liquidación Guardada con éxito" })
            getReembolsosPendientes()
          }
        } else if (result.isDismissed) {
          setOpenModal(true)
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  return {
    columns,
    Data,
    openModal,
    setOpenModal,
    handleDateChange,
    disabledDate,
    Fecha,
    columnsDet,
    FechaReembolso,
    Usuario_Solicita,
    DataDet,
    DataRIva,
    DataRFuente,
    DataGastos,
    onChangeCuentaRIva,
    CuentaRIva,
    onChangeCuentaRFuente,
    CuentaRFuente,
    onChangeCuentaGastos,
    CuentaGasto,
    PorcentajeIva,
    //onChangePorcentajeFuente,
    PorcentajeFuente,
    onChangeReembolso,
    Reembolso,
    onChangeRetencionIva,
    RetencionIva,
    onChangeRetencionFuente,
    RetencionFuente,
    onChangeCapacitacion,
    Capacitacion,
    btn_Guardar,
    showAlert,
    Message,
    Cod_ConceptoIVA,
    Cod_ConceptoFuente
  }
}
