import { Col, DatePicker, Row, Typography, Table, Input, Checkbox, Select, Button, Alert } from "antd"
import Modal from "antd/lib/modal/Modal"
import { useLayout } from "hooks/otros/useLayout"
import { DataTypeReembolsoDet, DataTypeReembolsos, useCrearLiquidacion } from "hooks/reembolsos/useCrearLiquidacion"
import React from "react"
const { Title } = Typography
const breadcrumbs = [
  { Label: "Reembolsos", Url: "/reembolsos" },
  { Label: "Crear Liquidación de Gastos", Url: "/reembolsos/crear-liquidacion" },
]
const openKeys = [breadcrumbs[0].Label, `${breadcrumbs[0].Label}/${breadcrumbs[1].Label}`]
const selectKeySider = `${breadcrumbs[0].Label}/${breadcrumbs[1].Label}`

export function CrearLiquidacion() {
  useLayout(breadcrumbs, openKeys, selectKeySider)
  const {
    columns,
    Data,
    openModal,
    setOpenModal,
    handleDateChange,
    disabledDate,
    Fecha,
    columnsDet,
    FechaReembolso,
    Usuario_Solicita,
    DataDet,
    DataRIva,
    DataRFuente,
    DataGastos,
    onChangeCuentaRIva,
    onChangeCuentaRFuente,
    onChangeCuentaGastos,
    CuentaGasto,
    onChangeReembolso,
    Reembolso,
    onChangeRetencionIva,
    RetencionIva,
    onChangeRetencionFuente,
    RetencionFuente,
    onChangeCapacitacion,
    Capacitacion,
    btn_Guardar,
    showAlert,
    Message,
    Cod_ConceptoIVA,
    Cod_ConceptoFuente
  } = useCrearLiquidacion()
  return (
    <>
      <Row style={{ background: "white", padding: "5px" }} align="middle" justify="center">
        <Col sm={12} xs={18} md={10} style={{ paddingLeft: "20px" }}>
          <Title level={3}>Liquidaciones Pendientes</Title>
        </Col>
        <Col sm={12} xs={6} md={14}>
          <Row justify="end">
            <DatePicker format={"YYYY/MM/DD"} disabledDate={disabledDate} onChange={handleDateChange} defaultValue={Fecha} />
          </Row>
        </Col>
      </Row>
      <Row style={{ background: "white", width: "100%", overflowX: "auto" }}>
        <Table dataSource={Data} style={{ width: "100%" }} columns={columns as DataTypeReembolsos[]} />
      </Row>

      <Modal
        title="Crear Liquidación de Gastos"
        open={openModal}
        onOk={() => setOpenModal(false)}
        onCancel={() => setOpenModal(false)}
        width={800}
        maskClosable={false}
        keyboard={false}
        footer={
          <>
          <Button type="default" onClick={() => setOpenModal(false)}>Cancelar</Button>
          <Button type="primary" onClick={btn_Guardar}>Guardar</Button>
          </>
        }
      >
        {showAlert&&<Alert message={Message} type="warning" showIcon />}
        <Row>
          <Col xs={24} sm={12} md={6} style={{ paddingRight: "10px" }}>
            <Row>
              <Col xs={24} sm={24} md={6}>
                <Title level={5} style={{ fontSize: "14px" }}>
                  Fecha:
                </Title>
              </Col>
              <Col xs={24} sm={24} md={18}>
                <Input size={"small"} style={{background:'white', color:'black'}} value={FechaReembolso?.toString()} disabled/>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={12} md={18} style={{ paddingRight: "10px" }}>
            <Row>
              <Col xs={24} sm={24} md={3}>
                <Title level={5} style={{ fontSize: "14px" }}>
                  Nombre:
                </Title>
              </Col>
              <Col xs={24} sm={24} md={21}>
                <Input size="small" style={{background:'white', color:'black'}} value={Usuario_Solicita?.toString()} disabled/>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row align={"middle"}>
          <Col sm={24} xs={24} md={12} style={{ paddingRight: "10px" }}>
            <Row>
              <Col sm={24} xs={24} md={12}>
                <Checkbox onChange={onChangeReembolso} checked={Reembolso}>Reembolso</Checkbox>
              </Col>
              <Col sm={24} xs={24} md={12}>
                <Checkbox onChange={onChangeRetencionIva} checked={RetencionIva}>Retencion Iva</Checkbox>
              </Col>
            </Row>
          </Col>
          <Col sm={24} xs={24} md={12} style={{ paddingRight: "10px" }}>
            <Row>
              <Col sm={24} xs={24} md={24}>
                <Row>
                  <Title style={{ fontSize: "10px", marginTop: "5px" }}>Porcentaje</Title>
                </Row>
                <Select
                  showSearch
                  size="small"
                  options={DataRIva}
                  onChange={onChangeCuentaRIva}
                  value={Cod_ConceptoIVA}
                  style={{ width: "100%" }}
                  filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                  disabled={!RetencionIva}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row align={"middle"}>
          <Col sm={24} xs={24} md={12} style={{ paddingRight: "10px" }}>
            <Row>
              <Col sm={24} xs={24} md={12}>
                <Checkbox  onChange={onChangeCapacitacion}  checked={Capacitacion}>Capacitación</Checkbox>
              </Col>
              <Col sm={24} xs={24} md={12}>
                <Checkbox onChange={onChangeRetencionFuente} checked={RetencionFuente}>Retencion Fuente</Checkbox>
              </Col>
            </Row>
          </Col>
          <Col sm={24} xs={24} md={12} style={{ paddingRight: "10px" }}>
            <Row>
              <Col sm={24} xs={24} md={24}>
                <Row>
                  <Title style={{ fontSize: "10px", marginTop: "5px" }}>Porcentaje</Title>
                </Row>
                <Select
                  showSearch
                  size="small"
                  options={DataRFuente}
                  onChange={onChangeCuentaRFuente}
                  value={Cod_ConceptoFuente}
                  style={{ width: "100%" }}
                  filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                  disabled={!RetencionFuente}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ marginRight: "10px" }}>
          <Title style={{ fontSize: "10px", marginTop: "5px" }}>Cuenta Gastos</Title>
          <Select
            showSearch
            options={DataGastos}
            onChange={onChangeCuentaGastos}
            value={CuentaGasto}
            style={{width: "100%"}}
            size="small"
            filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
            disabled={Reembolso}
          />
        </Row>
        <Row style={{ width: "100%", background: "white", overflowX: "auto", marginTop: "10px" }}>
          <Table
            pagination={false}
            dataSource={DataDet}
            style={{ width: "100%", background: "white" }}
            columns={columnsDet as DataTypeReembolsoDet[]}
            footer={(data) => {
              let total = data.reduce((total, fila) => total + fila.Total, 0)
              return (
                <>
                  <Row justify={"end"} style={{textAlign: "right", overflow:'auto' }}>
                    <Col sm={18} md={20} style={{padding: "5px" }}>
                      <Title level={5}>Total</Title>{" "}
                    </Col>
                    <Col sm={6} md={4} style={{ padding: "5px" }}>
                      <Title level={5}>{total?.toFixed(2)}</Title>
                    </Col>
                  </Row>
                </>
              )
            }}
          />
        </Row>
      </Modal>
    </>
  )
}
