import { useCallback, useEffect, useState } from "react";
import { optionsGET, url_interna } from "../../config/constants";

import { TableColumnsType } from "antd";

import { useLayout } from "hooks/otros/useLayout";

import { useSearchTableNew } from "hooks/otros/useSearchTableNew";
import { useExport } from "hooks/otros/useExport";

type Productos = {
  key: React.Key;
  Cod_Producto: string;
  Cod_MIA: string;
  Descripcion: string;
  PVP: number;
  DescuentoProducto: number;
  Precio: number;
  DLunes: number;
  DMartes: number;
  DMiércoles: number;
  DJueves: number;
  DViernes: number;
  DSábado: number;
  DDomingo: number;
  PLunes: number;
  PMartes: number;
  PMiércoles: number;
  PJueves: number;
  PViernes: number;
  PSábado: number;
  PDomingo: number;
};

export interface DatosPeriodo {
  label: string;
  value: number;
}
const breadcrumbs = [{ Label: "Reportes", Url: "/productos-descuentos" }, { Label: "Descuentos Dias" }];

const openKeys = [breadcrumbs[0].Label];
const selectKeySider = `${breadcrumbs[0].Label}/${breadcrumbs[1].Label}`;

export function useDescuentosDias() {
  useLayout(breadcrumbs, openKeys, selectKeySider);

  const [tipoMovimiento, setTipoMovimiento] = useState<number>();
  const { exportToExcel } = useExport();

  const [productos, setProductos] = useState<Productos[]>([]);
  const { getColumnSearchPropsNew: getColumnSearchPropsNewVisorReporte } = useSearchTableNew<Productos>();

  const getDatosIniciales = useCallback(async () => {
    setProductos([]);
    try {
      const respuesta = await fetch(url_interna + "api/reportes/productos-descuentos", optionsGET).then((data) =>
        data.json()
      );
      if (respuesta.msg) {
        const res: Productos[] = respuesta.data;

        if (res.length > 0) {
          setProductos(res);
        } else {
          setProductos([]);
        }
      } else {
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      return { msg: "no_conexion", status: false };
    }
  }, []);

  useEffect(() => {
    getDatosIniciales();
  }, [getDatosIniciales]);

  const handleClickDownloadData = () => {
    if (productos.length > 0) {
      let datosParaExportar = [];

      datosParaExportar = productos.map(({ ...resto }) => resto);

      datosParaExportar = datosParaExportar.map(({ key, ...resto }) => resto);
      exportToExcel(datosParaExportar, "Descuentos Productos Dias");
    }
  };

  const formateaCantidad = (cantidad: number) => {
    const totalCostoFormateado = (cantidad / 1).toLocaleString("de-DE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return totalCostoFormateado;
  };

  const columns: TableColumnsType<Productos> = [
    {
      title: "Cod_Sg",
      dataIndex: "Cod_Producto",
      key: "Cod_Producto",
      className: "customTbl",
      width: "75px",
      fixed: "left",
      ...getColumnSearchPropsNewVisorReporte("Cod_Producto"),
    },
    {
      title: "Cod_MIA",
      dataIndex: "Cod_MIA",
      key: "Cod_MIA",
      ...getColumnSearchPropsNewVisorReporte("Cod_MIA"),
      className: "customTbl",
      fixed: "left",
      width: "70px",
    },
    {
      title: "Descripcion",
      dataIndex: "Descripcion",
      key: "Descripcion",
      className: "customTbl",
      fixed: "left",
      ...getColumnSearchPropsNewVisorReporte("Descripcion"),
      width: "150px",
    },
    {
      title: "PVP",
      dataIndex: "PVP",
      key: "PVP",
      align: "right",
      className: "customTbl",
      fixed: "left",
      width: "80px",
      render: (_, record) => (
        <span style={{ color: record.PVP === 0 ? "lightgray" : "black" }}>{formateaCantidad(record.PVP)}</span>
      ),
    },
    {
      title: "Descuento",
      dataIndex: "DescuentoProducto",
      key: "DescuentoProducto",
      align: "right",
      className: "customTbl",
      fixed: "left",
      width: "80px",
      render: (_, record) => <span>{formateaCantidad(record.DescuentoProducto)}</span>,
    },
    {
      title: "Precio",
      dataIndex: "Precio",
      key: "Precio",
      align: "right",
      className: "customTbl",
      fixed: "left",
      width: "70px",
      render: (_, record) => <span>{formateaCantidad(record.Precio)}</span>,
    },
    // Días de la semana
    {
      title: "DLunes",
      dataIndex: "DLunes",
      key: "DLunes",
      className: "customTbl",
      width: "70px",
    },
    {
      title: "DMartes",
      dataIndex: "DMartes",
      key: "DMartes",
      className: "customTbl",
      width: "70px",
    },
    {
      title: "DMiércoles",
      dataIndex: "DMiércoles",
      key: "DMiércoles",
      className: "customTbl",
      width: "70px",
    },
    {
      title: "DJueves",
      dataIndex: "DJueves",
      key: "DJueves",
      className: "customTbl",
      width: "70px",
    },
    {
      title: "DViernes",
      dataIndex: "DViernes",
      key: "DViernes",
      className: "customTbl",
      width: "70px",
    },
    {
      title: "DSábado",
      dataIndex: "DSábado",
      key: "DSábado",
      className: "customTbl",
      width: "70px",
    },
    {
      title: "DDomingo",
      dataIndex: "DDomingo",
      key: "DDomingo",
      className: "customTbl",
      width: "70px",
    },
    {
      title: "PLunes",
      dataIndex: "PLunes",
      key: "PLunes",
      align: "right",
      width: "70px",
      className: "customTbl",
      render: (_, record) => <span>{formateaCantidad(record.PLunes)}</span>,
    },
    {
      title: "PMartes",
      dataIndex: "PMartes",
      key: "PMartes",
      align: "right",
      width: "70px",
      className: "customTbl",
      render: (_, record) => <span>{formateaCantidad(record.PMartes)}</span>,
    },

    {
      title: "PMiércoles",
      dataIndex: "PMiércoles",
      key: "PMiércoles",
      align: "right",
      width: "70px",
      className: "customTbl",
      render: (_, record) => <span>{formateaCantidad(record.PMiércoles)}</span>,
    },

    {
      title: "PJueves",
      dataIndex: "PJueves",
      key: "PJueves",
      align: "right",
      width: "70px",
      className: "customTbl",
      render: (_, record) => <span>{formateaCantidad(record.PJueves)}</span>,
    },

    {
      title: "PViernes",
      dataIndex: "PViernes",
      key: "PViernes",
      align: "right",
      width: "70px",
      className: "customTbl",
      render: (_, record) => <span>{formateaCantidad(record.PViernes)}</span>,
    },

    {
      title: "PSábado",
      dataIndex: "PSábado",
      key: "PSábado",
      width: "70px",
      align: "right",
      className: "customTbl",
      render: (_, record) => <span>{formateaCantidad(record.PSábado)}</span>,
    },

    {
      title: "PDomingo",
      dataIndex: "PDomingo",
      key: "PDomingo",
      align: "right",
      width: "70px",
      className: "customTbl",
      render: (_, record) => <span>{formateaCantidad(record.PDomingo)}</span>,
    },
  ];

  return {
    columns,
    productos,
    setTipoMovimiento,
    tipoMovimiento,
    handleClickDownloadData,
  };
}
