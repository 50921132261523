import { useEffect, useState, useCallback } from "react"
import { url } from "config/constants"
import { optionsGet, optionsPost } from "config/methods"
import moment from "moment"

import ExcelJS from "exceljs"
//import ExcelJS from 'exceljs';
//import XLSX from 'xlsx-style';
// import XLSXStyle from 'xlsx-style';

export function useControlDineroCaja() {
  //const [NameColumns, setNameColum]=useState<ColumnsType>()
  const [dataFarmacias, setdataFarmacias] = useState([{ label: "Seleccione una farmacia", value: "" }])
  const [valueFarmacia, setvalueFarmacia] = useState("")
  const [fechasSeleccionadas, setFechasSeleccionadas] = useState<[moment.Moment | null, moment.Moment | null]>([null, null])
  const [dataControlDinero, setdataControlDinero] = useState<any[]>([])

  const getFarmacias = async () => {
    try {
      const res = await fetch(`${url}fapifsg-pr/caja/control-dinero/getFarmacias`, optionsGet()).then((data) => data.json())
      //let respuesta=res.data;
      let newget = [{ value: "", label: "Seleccione una Farmacia" }]
      res.forEach((item: any) => {
        let add = {
          value: item.Cod_Bodega,
          label: item.Descripcion,
        }
        newget = [...newget, add]
      })
      setdataFarmacias(newget)
    } catch (error) {
      console.log(error)
    }
  }

  const getControlCaja = useCallback(async () => {
    try {
      //console.log(fechasSeleccionadas)
      const res = await fetch(
        `${url}fapifsg-pr/caja/control-dinero/getControlCaja`,
        optionsPost({ desde: fechasSeleccionadas[0], hasta: fechasSeleccionadas[1], cod_bodega: valueFarmacia })
      ).then((data) => data.json())
      //let respuesta=res.data;
      let newdata: any[] = []
      if (res) {
        res.forEach((item: any) => {
          let existFarmacia = newdata.filter((item2: any, key: any) => item2.Cod_Bodega === item.Cod_Bodega)
          if (existFarmacia.length === 0) {
            let Saldos = JSON.parse(item.Saldos)
            let newcolumns = {}
            let columns: any[] = []
            Saldos.forEach((item3: any) => {
              let datacolums = {
                [moment(item3.Fecha).format("DD/MM/YYYY")]: item3.Saldo.toFixed(2),
              }
              newcolumns = { ...newcolumns, ...datacolums }

              let addcolumns = {
                title: moment(item3.Fecha).format("DD/MM/YYYY"),
                dataIndex: moment(item3.Fecha).format("DD/MM/YYYY"),
                align: "right",
                render: (key: any) => {
                  return key ? key : "0.00"
                },
              }
              columns = [...columns, addcolumns]
            })
            let data = {
              Cod_Bodega: item.Cod_Bodega,
              Bodega: item.Bodega,
              dependientes: [{ key: item.Cod_Usuario, Usuario: item.Usuario, ...newcolumns, Total: item.Total.toFixed(2) }],
              dataexcel: [{ Usuario: item.Usuario, Saldo: item.Total.toFixed(2), ...newcolumns }],
              columnasprincipales: [
                { title: "Usuario", dataIndex: "Usuario", width: "300px", align: "left", fixed: "left" },
                { title: "Saldo", dataIndex: "Total", width: "100px", align: "right", fixed: "left" },
              ],
              columnasadd: columns,
            }
            newdata.push(data)
          } else {
            const data = newdata.map((item4) => {
              if (item4.Cod_Bodega === item.Cod_Bodega) {
                let Saldos = JSON.parse(item.Saldos)
                let newcolumns = {}
                let columns: any = []
                Saldos.forEach((item3: any) => {
                  let datacolums = {
                    [moment(item3.Fecha).format("DD/MM/YYYY")]: item3.Saldo.toFixed(2),
                  }
                  newcolumns = { ...newcolumns, ...datacolums }

                  let existcolum = item4.columnasadd.filter(
                    (item5: any) => item5.title === moment(item3.Fecha).format("DD/MM/YYYY")
                  )
                  if (existcolum < 1) {
                    let addcolumns = {
                      title: moment(item3.Fecha).format("DD/MM/YYYY"),
                      dataIndex: moment(item3.Fecha).format("DD/MM/YYYY"),
                      align: "right",
                      render: (key: any) => {
                        return key ? key : "0.00"
                      },
                    }
                    columns = [...columns, addcolumns]
                  }
                })
                let existuser = item4.dependientes.filter((item5: any) => item5.key === item.Cod_Usuario)
                let columnsToOrder = [...item4.columnasadd, ...columns]
                const ordercolumnas = columnsToOrder.sort((a, b) => {
                  const dateA = moment(a.title, "DD/MM/YYYY").valueOf() // Convertir a timestamp
                  const dateB = moment(b.title, "DD/MM/YYYY").valueOf()
                  return dateA - dateB // Orden ascendente
                })
                if (existuser.length < 1) {
                  //console.log(item.Cod_Usuario)
                  let datadependiente = [
                    { key: item.Cod_Usuario, Usuario: item.Usuario, ...newcolumns, Total: item.Total.toFixed(2) },
                  ]
                  let exceldata = [{ Usuario: item.Usuario, Saldo: item.Total.toFixed(2), ...newcolumns }]
                  //console.log(datadependiente)
                  return {
                    ...item4,
                    dependientes: [...item4.dependientes, ...datadependiente],
                    columnasadd: ordercolumnas,
                    dataexcel: [...item4.dataexcel, ...exceldata],
                  }
                }
                return item4
              }
              return item4
            })

            newdata = data
          }

          //console.log(item.Bodega)
        })
      }
      setdataControlDinero(newdata)
      //console.log(newdata)
    } catch (error) {
      console.log(error)
    }
  }, [fechasSeleccionadas, valueFarmacia])

  const onchangeFarmacia = async (value: string) => {
    setvalueFarmacia(value)
  }

  useEffect(() => {
    getFarmacias()
    getControlCaja()
  }, [getControlCaja])

  const disableFutureDates = (current: any) => {
    return current && current > moment().endOf("day")
  }

  const handleDateChange = (dates: any, dateStrings: any) => {
    setFechasSeleccionadas(dateStrings)
  }

  /* const exportToExcel = () => {
        let wb = XLSX.utils.book_new();
        let ws = XLSX.utils.aoa_to_sheet([]);
        let count:number=2
        XLSX.utils.sheet_add_aoa(ws, [['Dinero en Caja por Depositar']], { origin: 'A1' });
        ws['!merges'] = [
          { s: { r: 0, c: 0 }, e: { r: 0, c: 10 } }, // Combina de A1 a G1
        ];
      
        dataControlDinero.forEach((item:any)=>{
        let columns=[...item.columnasprincipales, ...item.columnasadd]
        columns=columns.map((item2:any)=>item2.title)
        XLSX.utils.sheet_add_aoa(ws, [[item.Bodega]], { origin: 'A'+count });
        XLSX.utils.sheet_add_aoa(ws, [columns], { origin: 'A'+(count+1) });
        XLSX.utils.sheet_add_json(ws, item.dataexcel, { origin: 'A'+(count+2), skipHeader: true });
        XLSX.utils.sheet_add_aoa(ws, [[]], { origin: 'A' + (count +3+ (item.dataexcel).length) });
        ws['!merges']?.push({ s: { r: (count-1), c: 0}, e: { r: (count-1), c: 10 } });
        count=count+3+ (item.dependientes).length;

    
        })
        console.log(ws['!merges'])
        XLSX.utils.book_append_sheet(wb, ws, 'Datos');
        XLSX.writeFile(wb, 'tabla-con-titulos.xlsx');
      }; */

  const exportToExcel = async () => {
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet("Control Dinero Caja")
    let numrow = 1
    // Definir las columnas
    worksheet.mergeCells(`A1:J1`)
    const mergedCell1 = worksheet.getCell(`A1`)
    mergedCell1.value = "DINERO EN CAJA POR DEPOSITAR"
    mergedCell1.font = { bold: true, size: 14 } // Cambiar el tamaño de la fuente de la celda combinada
    mergedCell1.alignment = { horizontal: "center" }

    dataControlDinero.forEach((item: any) => {
      let columnsprincipal = item.columnasprincipales.map((item2: any) => ({
        header: item2.title,
        key: item2.title,
        ancho: '500',
        //bold: true,
      }))

      let columnsadd = item.columnasadd.map((item2: any) => ({
        header: item2.title,
        key: item2.title,
        ancho: '60',
        //bold: false,
      }))

      let columns = [...columnsprincipal, ...columnsadd]

      // Combinar celdas para mostrar el valor de la bodega
      worksheet.mergeCells(`A${numrow + 1}:G${numrow + 1}`)
      const mergedCell = worksheet.getCell(`A${numrow + 1}`)
      mergedCell.value = item.Bodega
      mergedCell.font = { bold: true, size: 11 }
      //mergedCell.alignment = { horizontal: "center" }

      // Crear la fila de cabecera de la tabla en la posición actual
      const headerRow = worksheet.getRow(numrow + 2)
      headerRow.values = columns.map((col) => col.header) // Asignar los nombres de cabeceras

      // Aplicar formato a la fila de cabecera
      headerRow.eachCell((cell) => {
        cell.font = {
          //name: "Arial",
          size: 11,
          bold: true,
        }
      })
      headerRow.commit()

      // Añadir las filas de datos para esta tabla
      item.dataexcel.forEach((item2: any, index: number) => {
        const row = worksheet.getRow(numrow + 3 + index)

        // Extraer los valores de `item2` en el mismo orden de las columnas definidas
        const rowValues = columns.map((col) => (item2[col.key] !== undefined ? item2[col.key] : 0.00)); // Aquí alineamos las claves con los valores
        row.values = rowValues // Colocar los valores en la fila
        //console.log(rowValues)

        row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
          // Si la celda está vacía, asignar 0
          cell.font = { size: 11 }
          if (colNumber >= 2) {
           
              // Convertir a número si es un texto que representa un número
              if (typeof cell.value === "string" && !isNaN(Number(cell.value))) {
                cell.value = Number(cell.value) // Convertir texto a número
              }

            cell.alignment = { horizontal: "right" } // Alinear a la derecha
            cell.numFmt = "$#,##0.00" // Formato de contabilidad, puedes ajustar según tus necesidades
          }
        })

        row.commit()
      })

      // Actualizar `numrow` para la siguiente tabla
      numrow = numrow + item.dataexcel.length + 3
    })
    const buffer = await workbook.xlsx.writeBuffer()
    const blob = new Blob([buffer], { type: "application/octet-stream" })
    const url = window.URL.createObjectURL(blob)

    // Crear un enlace para descargar
    const a = document.createElement("a")
    a.href = url
    a.download = `Dinero_en_Caja_Deposito${moment().format("YYYYMMDD")}.xlsx`
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
  }

  return {
    dataFarmacias,
    valueFarmacia,
    onchangeFarmacia,
    disableFutureDates,
    handleDateChange,
    fechasSeleccionadas,
    dataControlDinero,
    exportToExcel,
  }
}
