import { Principal } from "pages/contentprincipal/Principal"
import { CrearLiquidacion } from "pages/contentreembolsogastos/CrearLiquidacion"
import { CrearReembolso } from "pages/contentreembolsogastos/CrearReembolso"
import CrearReembolsoOtros from "pages/contentreembolsogastos/CrearReembolsoOtros"
import { EditarReembolso } from "pages/contentreembolsogastos/EditarReembolso"
import { ReembolsoGastos } from "pages/contentreembolsogastos/ReembolsoGastos"
import { VerReembolsoGastos } from "pages/contentreembolsogastos/VerReembolsoGastos"
import { VerTodos } from "pages/contentreembolsogastos/contentvertodos/VerTodos"
import { type RouteObject } from "react-router-dom"

export const rutasReembolsoGastos: RouteObject[] = [
  {
    path: "/reembolsos",
    element: <Principal />,
  },
  {
    path: "/reembolsos/ver",
    element: <Principal />,
  },
  {
    path: "/reembolsos/:estado",
    element: <ReembolsoGastos />,
  },
  {
    path: "/crear-reembolso",
    element: <CrearReembolso />,
  },
  {
    path: "/ver-reembolso/:reembolso",
    element: <VerReembolsoGastos />,
  },
  {
    path: "/editar-reembolso/:reembolso",
    element: <EditarReembolso />,
  },
  {
    path: "/ver-todos",
    element: <VerTodos />,
  },
  {
    path: "/reembolsos/crear-reembolso-otros",
    element: <CrearReembolsoOtros />,
  },
  {
    path: "/reembolsos/crear-liquidacion",
    element: <CrearLiquidacion />,
  },
]
