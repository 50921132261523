import { Col, Row, DatePicker, Table, Card, Button, Spin } from "antd";
import "moment/locale/es";

import { ButtonExcel } from "components/otros/buttons";
import { useVT } from "virtualizedtableforantd4";
import { useReporteCargosPorTransferenciasGeneral } from "hooks/inventarios/logistica/useCargosTransferenciasGeneral";

export function CargosTransferenciasGeneral() {
  const [vt] = useVT(() => ({ scroll: { y: "55vh" } }), []);
  const {
    columns,
    handleChangeButton,
    estadoBotones,
    disabledDate,
    dataEstado,
    fechaInicio,
    fechaFin,
    setFechas,
    filteredData,
    handleClickDownloadData,
  } = useReporteCargosPorTransferenciasGeneral();
  return (
    <Row
      style={{ padding: "16px", marginRight: "0px", backgroundColor: "#fff" }}
      gutter={[16, 16]}
    >
      <Col xs={24} sm={12} md={14} lg={16} xl={18}>
        <DatePicker.RangePicker
          value={[fechaInicio, fechaFin]} // Mantiene el estado sincronizado
          onCalendarChange={(dates) => {
            if (dates && dates[0]) {
              setFechas([dates[0], dates[1]]); // Actualiza las fechas seleccionadas
            } else {
              setFechas([null, null]); // Permite reiniciar el rango al borrar o seleccionar nuevo rango
            }
          }}
          onChange={(dates) => {
            if (!dates) {
              setFechas([null, null]); // Reinicia el rango cuando se borra
            }
          }}
          disabledDate={disabledDate}
        />
        <Button
          type="primary"
          style={{ marginLeft: "5px" }}
          //icon={<PoweroffOutlined />}
          loading={estadoBotones}
          disabled={fechaInicio === null || fechaFin === null}
          onClick={handleChangeButton}
        >
          Generar reporte
        </Button>
      </Col>
      <Col xs={24} sm={12} md={10} lg={8} xl={6}>
        <Row
          gutter={[10, 10]}
          style={{
            marginBottom: "20px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {dataEstado && (
            <ButtonExcel
              buttonProps={{ onClick: handleClickDownloadData }}
            ></ButtonExcel>
          )}
        </Row>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Card>
          {!dataEstado && estadoBotones && (
            <div style={{ textAlign: "center" }}>
              <div style={{ margin: "0 auto" }}>
                <Spin />
              </div>
            </div>
          )}
          {dataEstado && (
            <Table
              columns={columns}
              dataSource={filteredData}
              components={vt}
              size="small"
              scroll={{ x: 900, y: "50vh" }}
              pagination={false}
              //pagination={{ pageSize: 10, hideOnSinglePage: true, position: ["bottomCenter"] }}
            />
          )}
        </Card>
      </Col>
    </Row>
  );
}

export default CargosTransferenciasGeneral;
