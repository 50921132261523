import { UserContext } from "context/UserContext"
import { useSearchTableNew } from "hooks/otros/useSearchTableNew"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { useExport } from "hooks/otros/useExport"
import { optionsPOST, url_interna } from "config/constants"
import { TableColumnsType } from "antd"
import { useVerifyResponse } from "hooks/otros/useVerifyReponse"
import { optionsGet } from "config/methods"

type TypeMinimosBodega = {
  key: React.Key
  Cod_Bodega: number
  Descripcion: string
  Codigo_SG_Abrev: string
  Cod_Producto: number
  Cod_MIA: string
  Producto: string
  Minimo: number
}

type Bodega = {
  label: string
  value: string
}

export default function useMinimosBodega() {
  const [DataMinimosBodega, setDataMinimosBodega] = useState<TypeMinimosBodega[]>([])
  const [bodega, setBodega] = useState<number>()
  const [searchText] = useState("")
  const { userData } = useContext(UserContext)
  const [estadoBotones, setDataBoton] = useState<boolean>(false)
  const [dataEstado, setDataEstado] = useState<boolean>(false)
  const [filteredData, setFilteredData] = useState<TypeMinimosBodega[]>([])
  const { getColumnSearchPropsNew } = useSearchTableNew<TypeMinimosBodega>()
  const [datosPeriodos, setDatosPeriodos] = useState<Bodega[]>([])
  const { exportToExcel } = useExport()
  const { isError } = useVerifyResponse()

  const formateaCantidad = (cantidad: number) => {
    const totalCostoFormateado = (cantidad / 1).toLocaleString("de-DE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    return totalCostoFormateado
  }

  const getMinimosBodega = async (codBodega: number) => {
    setDataEstado(false)
    setDataBoton(true)
    try {
      const res = await fetch(
        url_interna + "api/inventarios/get-minimos-bodega",
        optionsPOST(
          JSON.stringify({
            cod_bodega: codBodega,
          }),
          {
            "api-token": "apitoken",
          }
        )
      ).then((data) => data.json())

      let newdata: any = []
     
      if (res.msg!== 'err'&& (res.data).length>1) {
        setDataBoton(false);
        setDataEstado(true);
        // setestado(true)
        newdata = Array.from(res.data).map((item: any, key: any) => ({
          key: key,
          Cod_Bodega: item.Cod_Bodega,
          Descripcion: item.Descripcion,
          Codigo_SG_Abrev: item.Codigo_SG_Abrev,
          Cod_Producto: item.Cod_Producto,
          Cod_MIA: item.Cod_MIA,
          Producto: item.Producto,
          Minimo: item.Minimo,
        }))
        
      }
      
      setDataMinimosBodega(newdata)
    } catch (error) {
      console.error(error)
    }
  }

  const handleChangeButton = () => {
    if (bodega !== undefined) getMinimosBodega(bodega)
  }

  const calcularTotales = () => {
    let totalItems = DataMinimosBodega.length
    let TotalMinimos = 0
    DataMinimosBodega.forEach((item) => {
      TotalMinimos += item.Minimo
    })
    let unid = formateaCantidad(TotalMinimos)
    return { totalItems, unid }
  }

  const getbodegas = useCallback(async () => {
    setDatosPeriodos([])
    try {
      const codUsuario = userData?.id
      let res
      res = await fetch(url_interna + "api/inventarios/obtener_bodegas_piloto", optionsGet())
      if (isError(res)) throw new Error()
      const respuesta = await res.json()
      if (respuesta.msg !== "err") {
        const respuestas: Bodega[] = respuesta.data
        if (respuestas.length > 0) {
          setDatosPeriodos(
            respuestas.map(({ label, value }) => ({
              label,
              value,
            }))
          )
        } else {
          setDatosPeriodos([])
        }
      } else {
        return { msg: "Ocurrio un error", status: false }
      }
    } catch (e) {
      return { msg: "no_conexion", status: false }
    }
  }, [isError])

  useEffect(() => {
    getbodegas()
  }, [getbodegas])

  useEffect(() => {
    if (!searchText) {
      setFilteredData(DataMinimosBodega) // Si no hay texto de búsqueda, muestra todos los datos
    } else {
      const filtered = DataMinimosBodega.filter((item) => item.Descripcion.toLowerCase().includes(searchText.toLowerCase()))
      setFilteredData(filtered)
    }
  }, [searchText, DataMinimosBodega])

  const columns: TableColumnsType<TypeMinimosBodega> = [
    {
      title: "Cod Bodega",
      dataIndex: "Cod_Bodega",
      key: "Cod_Bodega",
      className: "letraPequenaVisor2",
      ...getColumnSearchPropsNew("Cod_Bodega"),
    },
    {
      title: "Descripción",
      dataIndex: "Descripcion",
      key: "Descripcion",
      className: "letraPequenaVisor2",
      ...getColumnSearchPropsNew("Descripcion"),
    },
    {
      title: "Codigo_SG_Abrev",
      dataIndex: "Codigo_SG_Abrev",
      key: "Codigo_SG_Abrev",
      ...getColumnSearchPropsNew("Codigo_SG_Abrev"),
      className: "letraPequenaVisor2",
    },
    {
      title: "Cod Producto",
      dataIndex: "Cod_Producto",
      key: "Cod_Producto",
      align:'right',
      ...getColumnSearchPropsNew("Cod_Producto"),
      className: "letraPequenaVisor2",
    },
    {
      title: "Cod MIA",
      dataIndex: "Cod_MIA",
      key: "Cod_MIA",
      align:'right',
      ...getColumnSearchPropsNew("Cod_MIA"),
      className: "letraPequenaVisor2",
    },
    {
      title: "Producto",
      dataIndex: "Producto",
      key: "Producto",
      ...getColumnSearchPropsNew("Producto"),
      // ...getColumnSearchPropsNew("Descripcion"),
      className: "letraPequenaVisor2",
    },
    {
      title: "Mínimo",
      dataIndex: "Minimo",
      key: "Minimo",
      className: "letraPequenaVisor2",
      align: "right",
      sorter: (a, b) => a.Minimo - b.Minimo,
    },
  ]

  const handleClickDownloadData = () => {
      const dataExport = DataMinimosBodega.map((item)=>{
          return{
            "Cod_Bodega": item.Cod_Bodega,
            Descripcion: item.Descripcion,
            Codigo_SG_Abrev: item.Codigo_SG_Abrev,
            Cod_Producto: item.Cod_Producto,
            Cod_MIA: item.Cod_MIA,
            Producto: item.Producto,
            "Minimo": item.Minimo
          }
      })
    if (bodega !== undefined)
      exportToExcel(dataExport, `Minimos FSG# ${bodega <= 16 ? bodega - 1 : bodega} `);
  };


  return {
    columns,
    filteredData,
    handleClickDownloadData,
    datosPeriodos,
    setBodega,
    bodega,
    dataEstado,
    estadoBotones,
    handleChangeButton,
    calcularTotales,
  }
}
