import { Moment } from "moment";
import { useEffect, useState } from "react";
import { optionsPOST, url_interna } from "../../config/constants";

import { EyeOutlined } from "@ant-design/icons";
import { useLocalStorage } from "hooks/otros/useLocalStorage";
import { useVerifyResponse } from "hooks/otros/useVerifyReponse";
import moment from "moment";
import { Button, TableColumnsType } from "antd";

import { useLayout } from "hooks/otros/useLayout";

import { useSearchTableNew } from "hooks/otros/useSearchTableNew";
import { useExport } from "hooks/otros/useExport";
import { swalError } from "config/methods";

type VentasCredito = {
  Fecha: string;
  Documento: String;
  key: React.Key;
  Cod_Cliente: number;
  Nombres: string;
  Cod_Bodega: number;
  Descripcion: string;
  Base_0: number;
  Base_Iva: number;
  Iva: number;
  Tipo: string;
  Cod_Venta:number;
  Total: number;
};
type VentasDetalladas = {
  Cod_Producto: number;
  Cod_MIA: string;
  Producto: string;
  Unidad: number;
  Fraccion: number;
  productoDv: number;
  Valor: number;
 
  key: React.Key;
};
const breadcrumbs = [
  { Label: "Reportes", Url: "/reporte" },
  { Label: "Ventas", Url: "/reportes/ventas" },
  { Label: "Creditos" },
];
//const breadcrumbs = [{ Label: "Inventarios", Url: "/inventario" }, { Label: "Productividad" }];

const openKeys = [breadcrumbs[0].Label, breadcrumbs[0].Label + "/" + breadcrumbs[1].Label];
export function useReporteVentasCredito() {
  useLayout(breadcrumbs, openKeys, breadcrumbs[1].Label + "/" + breadcrumbs[2].Label);
  const [datos, setDatos] = useState<VentasCredito[]>([]);
  const [datosDescripcion, setDatosDescripcion] = useState<VentasDetalladas[]>([]);
  const [searchText] = useState("");
  const [filteredData, setFilteredData] = useState<VentasCredito[]>([]);

  const { getColumnSearchPropsNew } = useSearchTableNew<VentasCredito>();

  const { exportToExcel } = useExport();
  let { optionsPost } = useLocalStorage();
  const { isError } = useVerifyResponse();
  const [[fechaInicio, fechaFin], setFechas] = useState<[Moment | null, Moment | null]>([null, null]);
  const [dataEstado, setDataEstado] = useState<boolean>(false);
  const [dataEstado2, setDataEstado2] = useState<boolean>(false);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [estadoBotonesDescripcion, setDataBotonDescripcion] = useState<boolean>(false);
  const [dataEstadoDescripcion, setDataEstadoDescripcion] = useState<boolean>(false);
  const [estadoBotones, setDataBoton] = useState<boolean>(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const formateaCantidad = (cantidad: number) => {
    const totalCostoFormateado = (cantidad / 1).toLocaleString("de-DE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return totalCostoFormateado;
  };

  useEffect(() => {
    if (!searchText) {
      setFilteredData(datos); // Si no hay texto de búsqueda, muestra todos los datos
    } else {
      const filtered = datos.filter((item) => item.Nombres.toLowerCase().includes(searchText.toLowerCase()));
      setFilteredData(filtered);
    }
  }, [searchText, datos]);

  const columns: TableColumnsType<VentasCredito> = [
    {
      title: "Tipo",
      dataIndex: "Tipo",
      key: "Tipo",
      className: "letraPequenaVisor2",
      width: "50px",
    },
    {
      title: "Documento",
      dataIndex: "Documento",
      key: "Documento",
      className: "letraPequenaVisor2",
    },
    {
      title: "Fecha",
      dataIndex: "Fecha",
      key: "Fecha",
      width: "100px",
      className: "letraPequenaVisor2",
    },
    {
      title: "Persona",
      dataIndex: "Nombres",
      key: "Nombres",
      ...getColumnSearchPropsNew("Nombres"),
      className: "letraPequenaVisor2",
    },
    {
      title: "Bodega",
      dataIndex: "Descripcion",
      key: "Descripcion",
      className: "letraPequenaVisor2",
      ...getColumnSearchPropsNew("Descripcion"),
    },
    {
      title: "Base 0",
      dataIndex: "Base_0",
      key: "Base_0",
      className: "letraPequenaVisor2",
      align: "right",
      width: "100px",
      sorter: (a, b) => a.Base_0 - b.Base_0,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Base 12",
      dataIndex: "Base_Iva",
      key: "Base_Iva",
      width: "100px",
      className: "letraPequenaVisor2",
    },
    {
      title: "Iva",
      dataIndex: "Iva",
      key: "Iva",
      width: "100px",
      className: "letraPequenaVisor2",
    },
    {
      title: "Total",
      dataIndex: "Total",
      key: "Total",
      align: "right",
      className: "letraPequenaVisor2",
      width: "100px",
      render: (_, record) => (
        <span style={{ color: record.Total === 0 ? "lightgray" : "black" }}>{formateaCantidad(record.Total)}</span>
        // <span style={{ color: record.total === 0 ? "lightgray" : "black" }}>{record.total}</span>
      ),
    },
    {
      title: "Datos",
      dataIndex: "Datos",
      align: "center",
      key: "Datos",
      render: (value, record) => (
        <Button
          icon={<EyeOutlined style={{ fontSize: "1.3rem" }} />}
          type="text"
          onClick={() => {
            if (fechaInicio && fechaFin) {
              getDatosCreditos(record.Cod_Venta);
            }
          }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          block
        />
      ),
      fixed: "right",
    },
  ];
  const getDatosCreditos = async (codVenta: number) => {
    setDataEstadoDescripcion(false);
    setDataBotonDescripcion(true);
    try {
      const res = await fetch(
        url_interna + "api/reportes/ventas_credito_detalladas",
        optionsPOST(
          JSON.stringify({
            codVenta: codVenta,
          }),
          {
            "api-token": "apitoken",
          }
        )
      );

      if (isError(res)) throw new Error();
      const respuesta = await res.json();

      if (respuesta.msg !== "err") {
        const respuestass: VentasDetalladas[] = respuesta.data;
        setDataEstadoDescripcion(true);
        setDataBotonDescripcion(false);
        setDatosDescripcion(
          respuestass.map(({ Cod_Producto, Cod_MIA, Producto, Unidad, Fraccion, productoDv, Valor, key }) => ({
            Cod_Producto,
            Cod_MIA,
            Producto,
            Unidad,
            Fraccion,
            productoDv,
            Valor,
            key,
          }))
        );
        showModal();
      } else {
        setDataEstadoDescripcion(false);
        setDataBotonDescripcion(false);
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      setDataEstadoDescripcion(false);
      setDataBotonDescripcion(false);
      return { msg: "no_conexion", status: false };
    }
  };
  const disabledDate = (current: moment.Moment) => {
    if (!fechaInicio) {
      return current && current >= moment().endOf("day"); // Mientras no haya fecha inicial, deshabilita fechas futuras
    }
    const minDate = fechaInicio.clone().startOf("day");
    const maxDate = fechaInicio.clone().add(6, "months").add(1, "days").endOf("day");
    const today = moment().startOf("day"); // Fecha actual (final del día)

    return current && (current < minDate || current > maxDate || current > today);
  };
  const getCargosTransferencias = async (fechaInicio: Moment, fechaFin: Moment) => {
    setDataEstado(false);
    setDataBoton(true);
    try {
      const res = await fetch(
        url_interna + "api/reportes/ventas_credito",
        optionsPOST(
          JSON.stringify({
            Tipo: 0,
            fechaInicio: fechaInicio!.format("DD/MM/YYYY"),
            fechaFin: fechaFin!.format("DD/MM/YYYY"),
          }),
          {
            "api-token": "apitoken",
          }
        )
      );
      if (isError(res)) throw new Error();
      const respuesta = await res.json();

      if (respuesta.msg !== "err") {
        const respuestas: VentasCredito[] = respuesta.data;
        setDataEstado(true);
        setDataBoton(false);
        setDatos(
          respuestas.map(
            ({
              Documento,
              Cod_Venta,
              Fecha,
              Cod_Cliente,
              Nombres,
              Cod_Bodega,
              Descripcion,
              Base_0,
              Base_Iva,
              Iva,
              Total,
              Tipo,
              key,
            }) => ({
              Documento,
              Cod_Venta,
              Fecha,
              Cod_Cliente,
              Nombres,
              Cod_Bodega,
              Descripcion,
              Base_0,
              Base_Iva,
              Iva,
              Total,
              Tipo,
              key,
            })
          )
        );
      } else {
        setDataEstado(false);
        setDataBoton(false);
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      setDataEstado(false);
      setDataBoton(false);
      return { msg: "no_conexion", status: false };
    }
  };

  const handleChangeButton = () => {
    try {
      if (fechaInicio && fechaFin) {
        getCargosTransferencias(fechaInicio, fechaFin);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickDownloadDataALl = async () => {
    setDataEstado2(true);
    try {
      const res = await fetch(
        url_interna + "api/reportes/ventas_credito",
        optionsPOST(
          JSON.stringify({
            Tipo: 1,
            fechaInicio: fechaInicio!.format("DD/MM/YYYY"),
            fechaFin: fechaFin!.format("DD/MM/YYYY"),
          }),
          {
            "api-token": "apitoken",
          }
        )
      );
      if (isError(res)) throw new Error();
      const respuesta = await res.json();

      if (respuesta.msg !== "err") {
        const datosParaExportar = respuesta.data.map(({ ...resto }) => resto);
        exportToExcel(
          datosParaExportar,
          "Reporte ventas general a credito" +
            fechaInicio!.format("DD/MM/YYYY") +
            " - " +
            fechaFin!.format("DD/MM/YYYY")
        );
        setDataEstado2(false);
      } else {
        setDataEstado2(false);
        swalError({ text: "Error al obtener los datos" });
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      setDataEstado2(false);
      swalError({ text: "Error al obtener los datos" });
      return { msg: "no_conexion", status: false };
    }
  };

  const handleClickDownloadData = () => {
    const datosParaExportar = datos.map(({ key, ...resto }) => resto);
    exportToExcel(
      datosParaExportar,
      "Reporte ventas a credito" + fechaInicio!.format("DD/MM/YYYY") + " - " + fechaFin!.format("DD/MM/YYYY")
    );
  };

  const handleClickDownloadData2 = () => {
    const datosParaExportar = datosDescripcion.map(({ key, ...resto }) => resto);
    exportToExcel(
      datosParaExportar,
      "Reporte de venta detallado" + fechaInicio!.format("DD/MM/YYYY") + " - " + fechaFin!.format("DD/MM/YYYY")
    );
  };

  const calcularTotales = () => {
    let total = 0.0;

    datosDescripcion.forEach((item) => {
      total += item.Valor;
    });
    total = parseFloat(total.toFixed(2));
    let valor = formateaCantidad(total);
    return { valor };
  };
  const columnsProductos: TableColumnsType<VentasDetalladas> = [
    {
      title: "Cod SG",
      dataIndex: "Cod_Producto",
      key: "Cod_Producto",
      className: "letraPequenaVisor2",
      width: "100px",
    },
    {
      title: "Cod Mia",
      dataIndex: "Cod_MIA",
      key: "Cod_MIA",
      className: "letraPequenaVisor2",
      width: "100px",
    },
    {
      title: "Producto",
      dataIndex: "Producto",
      key: "Producto",
      className: "letraPequenaVisor2",
    },
    {
      title: "Unidad",
      dataIndex: "Unidad",
      align: "right",
      className: "letraPequenaVisor2",
      width: "100px",
      /*heightrender: (_, record) => (
        <span style={{ color: record.CantReal === 0 ? "lightgray" : "black" }}>{formateaCantidad(record.CantReal)}</span>
        // <span style={{ color: record.total === 0 ? "lightgray" : "black" }}>{record.total}</span>
      ),*/
    },
    {
      title: "Fraccion",
      dataIndex: "Fraccion",
      align: "right",
      className: "letraPequenaVisor2",
      width: "100px",
      /*heightrender: (_, record) => (
        <span style={{ color: record.CantReal === 0 ? "lightgray" : "black" }}>{formateaCantidad(record.CantReal)}</span>
        // <span style={{ color: record.total === 0 ? "lightgray" : "black" }}>{record.total}</span>
      ),*/
    },
    {
      title: "Valor",
      dataIndex: "Valor",
      align: "right",
      className: "letraPequenaVisor2",
      width: "130px",
      render: (_, record) => (
        <span style={{ color: record.Valor === 0 ? "lightgray" : "black" }}>{formateaCantidad(record.Valor)}</span>
        // <span style={{ color: record.total === 0 ? "lightgray" : "black" }}>{record.total}</span>
      ),
    },
  ];
  return {
    columns,
    filteredData,
    handleChangeButton,
    estadoBotones,
    disabledDate,
    dataEstado,
    getCargosTransferencias,
    searchText,
    optionsPost,
    fechaInicio,
    fechaFin,
    setFechas,
    handleClickDownloadData,
    handleClickDownloadData2,
    isModalOpen,
    handleCancel,
    estadoBotonesDescripcion,
    dataEstadoDescripcion,
    handleOk,
    datosDescripcion,
    columnsProductos,
    calcularTotales,
    handleClickDownloadDataALl,
    dataEstado2,
    // calcularTotales,
  };
}
