import { ColumnsType } from "antd/lib/table"
import { optionsPOST, url_interna } from "config/constants"
import { useExport } from "hooks/otros/useExport"
import { useSearchTableNew } from "hooks/otros/useSearchTableNew"
import moment from "moment"
import React, { useCallback, useEffect, useState } from "react"

export type ResumeBodega = {
  key: React.Key
  cod_bodega: number
  establecimiento: string
  descripcion: string
  total: number
  base_0: number
  base_iva: number
}

export default function useResumenporBodega() {
  const [dataResumeBodega, setdataResumeBodega] = useState<ResumeBodega[]>([])
  const [estado, setestado] = useState(false)
  const [fechasSeleccionadas, setFechasSeleccionadas] = useState<[moment.Moment, moment.Moment]>()
  const { getColumnSearchPropsNew } = useSearchTableNew<ResumeBodega>()
  const { exportToExcel } = useExport()

  const handleDateChange = (dates: any, dateStrings: any) => {
    setFechasSeleccionadas(dates)
  }

  const getResumenBodega = useCallback(async () => {
    try {
      setestado(false)
      const res = await fetch(
        `${url_interna}api/reportes/resumen-bodega`,
        optionsPOST(
          JSON.stringify({
            fecha_inicio: fechasSeleccionadas ? fechasSeleccionadas[0].format("YYYY-MM-DD") : "",
            fecha_fin: fechasSeleccionadas ? fechasSeleccionadas[1].format("YYYY-MM-DD") : "",
          }),
          {
            "api-token": "apitoken",
          }
        )
      ).then((data) => data.json())

      let newdata: any = []
      if (res) {
        setestado(true)
        newdata = Array.from(res).map((item: any, key: any) => ({
          key: key,
          cod_bodega: item.Cod_Bodega,
          establecimiento: item.Establecimiento,
          descripcion: item.Descripcion,
          total: item.Total,
          base_0: item.Base_0,
          base_iva: item.Base_Iva,
        }))
      }
      setdataResumeBodega(newdata)
      setestado(true)
    } catch (error) {
      console.error(error)
    }
  }, [fechasSeleccionadas])

  const handleDownload = () => {
    const dataExport = dataResumeBodega.map((item) => {
      return {
        Cod_Bodega: item.cod_bodega,
        Establecimiento: item.establecimiento,
        Descripcion: item.descripcion,
        Total: item.total,
        Base_0: item.base_0,
        Base_Iva: item.base_iva,
      }
    })
    exportToExcel(dataExport, `ReporteVentasporBodega${moment().format("DDMMYYYY")}`)
  }

  const columns: ColumnsType<ResumeBodega> = [
    { title: "Código Bodega", dataIndex: "cod_bodega", className: "fontsize", ...getColumnSearchPropsNew("cod_bodega"), align:'right' },
    {
      title: "Descripción SRI",
      dataIndex: "descripcion",
      className: "fontsize",
      align: "left",
      ...getColumnSearchPropsNew("descripcion"),
    },
    { title: "Establecimiento", dataIndex: "establecimiento", className: "fontsize", align: "center" },
    { title: "Total", dataIndex: "total", className: "fontsize", align: "right" },
    { title: "Base 0", dataIndex: "base_0", className: "fontsize", align: "right" },
    { title: "Base Iva", dataIndex: "base_iva", className: "fontsize", align: "right" },
  ]

  const disableFutureDates = (current: any) => {
    return current && current > moment().endOf("day")
  }

  useEffect(() => {
    getResumenBodega()
  }, [getResumenBodega])

  return {
    disableFutureDates,
    handleDateChange,
    dataResumeBodega,
    columns,
    estado,
    fechasSeleccionadas,
    handleDownload,
  }
}
