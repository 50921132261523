import { UploadProps } from 'antd';
import {  url } from 'config/constants';
import { optionsPost, swalQuestion, swalSuccess, swalWarning } from 'config/methods';
import { UserContext } from "context/UserContext"
import  { useContext, useState } from 'react'
import * as XLSX from "xlsx";



export type TypeMinimos={
    Cod_Bodega: number,
    Cod_Producto: number,
    Minimo: number
}

export default function useSubirMinimos() {

  const [dataExcel, setdataExcel]=useState<TypeMinimos[]>([])
  const [num_filas, setnum_filas]=useState<Number>(0)
  const { userData } = useContext(UserContext);
  const [estado, setestado]= useState(false)
  const [filename, setfilename]=useState("")

    const handleFileUpload: UploadProps["beforeUpload"] = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const binaryStr = e.target?.result;
          if (!binaryStr) return;
    
          const workbook = XLSX.read(binaryStr, { type: "binary" });
          const sheetName = workbook.SheetNames[0]; // Leer la primera hoja
          const sheet = workbook.Sheets[sheetName];
          let parsedData: TypeMinimos[] = XLSX.utils.sheet_to_json(sheet);

          const filteredData = parsedData.map(({ Cod_Bodega, Cod_Producto, Minimo }) => ({ Cod_Bodega, Cod_Producto, Minimo }));
          const filtro = filteredData.filter((item)=>item.Cod_Bodega!==undefined&& item.Cod_Producto!==undefined && item.Minimo!==undefined && item.Minimo>=0)
          
          if(filteredData.length!==filtro.length){
            swalWarning({text:"Hay errores en el Archivo. Revise y vuelva a intentarlo"})
            setnum_filas(0)
            setfilename("")
            return
          }
          setdataExcel(parsedData);
          setnum_filas(filteredData.length)
          //
        };
        
        reader.readAsArrayBuffer(file);
        setfilename(file.name)
        return false; // Evita la subida automática
      }

      const btn_Guardar=async()=>{
              try{
                if (dataExcel?.length<=0) { 
                  swalWarning({text:"No hay datos para subir"})
                  return}
                  swalQuestion({
                          text: `¿Esta seguro de Actualizar Minimos en las Bodegas?`,
                          icon: "warning",
                          showCancelButton: true,
                        }).then(async (result) => {
                          if (result.isConfirmed) {
                            setestado(true)
                            const res = await fetch(
                                `${url}fapifsg-pr/inventarios/update-minimos`,
                                optionsPost(
                                  {data: dataExcel, Id_Usuario:userData?.id}
                                )
                              ).then((data) => data.json())
                              if (res.status==='200'){
                                swalSuccess({text:res.data})
                                setdataExcel([])
                                setnum_filas(0)
                                setfilename("")
                                setestado(false)
                                }else{
                                  swalWarning({text:res.data})
                                  setestado(false)
                                  setdataExcel([])
                                  setnum_filas(0)
                                  setfilename("")
                                }
                          }})
              }catch(error){
                console.log(error)
              }
            }
    

  return {
    handleFileUpload,
    num_filas,
    btn_Guardar,
    estado,
    filename
  }
}
