import { Col, Row, Space, Typography, DatePicker, Button, Table, Spin } from "antd"
import React from "react"
import { FileExcelOutlined } from "@ant-design/icons"
import { useLayout } from "hooks/otros/useLayout"
import { ReporteBonificacion, useReporteVentasMF } from "hooks/productos/useReporteVentasMF"

const { Title } = Typography
const { RangePicker } = DatePicker

const breadcrumbs = [
  { Label: "Productos", Url: "productos" },
  { Label: "Promociones", Url: "/productos/promociones" },
  { Label: "Ventas Bonificaciones(MF)" },
]
const openKeys = [breadcrumbs[0].Label, `${breadcrumbs[0].Label}/${breadcrumbs[1].Label}`]
const selectKeySider = `${breadcrumbs[1].Label}/${breadcrumbs[2].Label}`

export default function ReporteVentasMF() {
  useLayout(breadcrumbs, openKeys, selectKeySider)

  const { columns, DataBonificacion, fechasSeleccionadas, handleDateChange, estado, handleDownload , disabledDate} = useReporteVentasMF()
  return (
    <>
      {" "}
      <Row style={{ background: "white", padding: "5px", paddingLeft: "10px" }}>
        <Col xs={24} sm={24} md={12}>
          <Title level={3}>Reporte Ventas Bonificaciones (MF)</Title>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Row justify={"end"}>
            <Col sm={24} md={11}>
              <Row style={{ fontSize: "10px", marginRight: "5px" }}>Fechas:</Row>
              <Row style={{ marginTop: "5px" }}>
                <Space>
                  <RangePicker
                    size="small"
                    defaultValue={fechasSeleccionadas}
                    //format={"DD/MM/YYYY"}
                     disabledDate={disabledDate}  onChange={handleDateChange}
                    style={{ marginLeft: "2px" }}
                  />
                </Space>
              </Row>
            </Col>
            <Col style={{ marginTop: "12px", marginLeft: "3px" }}>
              <Button
                type="primary"
                style={{ marginRight: "2px", marginTop: "1px", backgroundColor: "#00B050", borderColor: "#00B050" }}
                onClick={handleDownload}
              >
                Excel <FileExcelOutlined />
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      {!estado && (
        <Row
          style={{
            justifyContent: "center",
            textAlign: "center",
            display: "flex",
            background: "white",
            padding: "40px",
            height: "100%",
            paddingTop: "80px",
          }}
        >
          <Spin style={{ fontSize: "20px" }} />
        </Row>
      )}
      {estado && (
        <Row style={{ alignItems: "center", background: "white", padding: "5px", height: "100%" }}>
          <Table
            style={{ width: "100%", height: "100%", overflowX: "auto" }}
            columns={columns as ReporteBonificacion[]}
            dataSource={DataBonificacion}
            //rowClassName={(record, index) => 'miFilaSeleccionada'}
          />
        </Row>
      )}
    </>
  )
}
