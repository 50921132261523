import { Card, Col, Row, Upload, Typography, Button, Spin, Tag } from "antd"
import { useLayout } from "hooks/otros/useLayout"
import { InboxOutlined } from "@ant-design/icons"
import useSubirMinimos from "hooks/inventarios/logistica/useSubirMinimos"

const { Title } = Typography
const breadcrumbs = [
  { Label: "Inventarios", Url: "inventarios" },
  { Label: "Logistica", Url: "/inventarios/logistica" },
  { Label: "Subir Mínimos" },
]

const openKeys = [breadcrumbs[0].Label, `${breadcrumbs[0].Label}/${breadcrumbs[1].Label}`]
const selectKeySider = `${breadcrumbs[1].Label}/${breadcrumbs[2].Label}`

export default function SubirMinimos() {
  useLayout(breadcrumbs, openKeys, selectKeySider)
  const { handleFileUpload, num_filas, btn_Guardar, estado, filename } = useSubirMinimos()

  return (
    <>
      <Row style={{ background: "white", padding: "5px", paddingLeft: "10px" }}>
        <Col xs={24} sm={24} md={12}>
          <Title level={3}>Actualizar Mínimos de Productos</Title>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Row justify={"end"}>
            <Button type="primary" onClick={btn_Guardar} disabled={estado}> 
              Procesar
            </Button>
          </Row>
        </Col>
      </Row>
      <Row style={{ width: "100%", background: "white", padding: "10px" }}>
        <Col md={24} xs={24} sm={24} lg={24}>
          <Card style={{ margin: "2px", height: "100%" }}>
            {!estado&&<Upload.Dragger accept=".xlsx, .xls" disabled={estado} beforeUpload={handleFileUpload} showUploadList={false}  
                  defaultFileList={[]}
      className="upload-list-inline"
            maxCount={1} listType="picture">
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Haz clic o arrastra un archivo para subirlo</p>
              <p className="ant-upload-hint">Solo se permiten archivos Excel (.xls, .xlsx)</p>
            </Upload.Dragger>}
            <Card style={{marginTop:'10px'}}>
            <Row><Title level={5}>Archivo  {filename!==''&&<Tag color="geekblue">{"     "+filename}</Tag>}
            </Title></Row>
            <Row><Title level={5}>Número de Filas {filename!==''&&<Tag color="geekblue" style={{ color: "#7a0c00", textAlign: "center", fontSize :'18px'}}>
              {num_filas.toString()}
            </Tag>}</Title></Row></Card>
            {estado &&<Row style={{ width: "100%", background: "white", marginTop:'10px' }}>
        <Col md={24} xs={24} lg={24} sm={24} style={{ alignContent: "center" }}>
          <Card>
            <Title level={4} style={{ textAlign: "center" }}>
              Subiendo Información...
            </Title>
            <Row
              style={{
                justifyContent: "center",
                textAlign: "center",
                display: "flex",
                background: "white",
                padding: "40px",
                height: "100%",
                paddingTop: "20px",
              }}
            >
              <Spin size="large" />
            </Row>
          </Card>
        </Col>
      </Row>}
          </Card>
        </Col>
      </Row>
    </>
  )
}
