import { useExport } from 'hooks/otros/useExport';
import { useLocalStorage } from 'hooks/otros/useLocalStorage';
import { useSearchTableNew } from 'hooks/otros/useSearchTableNew';
import { useVerifyResponse } from 'hooks/otros/useVerifyReponse';
import React, { useEffect, useState } from 'react'
import { Moment } from 'moment';
import { TableColumnsType } from 'antd';
import moment from 'moment';
import { optionsPOST, url_interna } from 'config/constants';

export type CargosTransferenciasReporte = {
  key: React.Key;
  Cod_Bodega: number;
  Bodega: number;
  Cod_Producto: number;
  Cod_MIA: number;
  Cod_Barra: string;
  Producto: string;
  Cantidad: number;
  Unidad_Medida:number;
  CostoFraccion: number;
};


export default function useOtrosMotivos() {
const [datos, setDatos] = useState<CargosTransferenciasReporte[]>([]);
  const [searchText] = useState("");
  const [filteredData, setFilteredData] = useState<CargosTransferenciasReporte[]>([]);
  const { getColumnSearchPropsNew } = useSearchTableNew<CargosTransferenciasReporte>();
  const { exportToExcel } = useExport();
  let { optionsPost } = useLocalStorage();
  const { isError } = useVerifyResponse();
  const [[fechaInicio, fechaFin], setFechas] = useState<[Moment | null, Moment | null]>([null, null]);
  const [dataEstado, setDataEstado] = useState<boolean>(false);

  const [estadoBotones, setDataBoton] = useState<boolean>(false);

  /*const formateaCantidad = (cantidad: number) => {
    const totalCostoFormateado = (cantidad / 1).toLocaleString("de-DE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return totalCostoFormateado;
  };
*/
  useEffect(() => {
    if (!searchText) {
      setFilteredData(datos); // Si no hay texto de búsqueda, muestra todos los datos
    } else {
      const filtered = datos.filter((item) => item.Producto.toLowerCase().includes(searchText.toLowerCase()));
      setFilteredData(filtered);
    }
  }, [searchText, datos]);

  const columns: TableColumnsType<CargosTransferenciasReporte> = [
    {
      title: "Cod_B",
      dataIndex: "Cod_Bodega",
      key: "Cod_Bodega",
      className: "letraPequenaVisor2",
      width: "50px",
    },
    {
      title: "Bodega",
      dataIndex: "Bodega",
      key: "Bodega",
      width: "150px",
      className: "letraPequenaVisor2",
      ...getColumnSearchPropsNew("Bodega"),
    },
    {
      title: "Cod producto",
      dataIndex: "Cod_Producto",
      key: "Cod_Producto",
      className: "letraPequenaVisor2",
      width: "100px",
    },
    {
      title: "Cod MIA",
      dataIndex: "Cod_MIA",
      key: "Cod_MIA",
      width: "100px",
      className: "letraPequenaVisor2",
    },
    {
      title: "Cod Barra",
      dataIndex: "Cod_Barra",
      key: "Cod_Barra",
      width: "150px",
      className: "letraPequenaVisor2",
    },
    {
      title: "Producto",
      dataIndex: "Producto",
      key: "Producto",
      width: "300px",
      className: "letraPequenaVisor2",
      ...getColumnSearchPropsNew("Producto"),
    },
    {
      title: "Costo Fraccion",
      dataIndex: "CostoFraccion",
      key: "CostoFraccion",
      className: "letraPequenaVisor2",
      align: "right",
    },
    {
      title: "Cantidad",
      dataIndex: "Cantidad",
      key: "Cantidad",
      className: "letraPequenaVisor2",
      align: "right",
      sorter: (a, b) => a.Cantidad - b.Cantidad,
      sortDirections: ["descend", "ascend"],
      /*render: (_, record) => (
        <span style={{ color: record.Cantidad === 0 ? "lightgray" : "black" }}>
          {formateaCantidad(record.Cantidad)}
        </span>
        // <span style={{ color: record.total === 0 ? "lightgray" : "black" }}>{record.total}</span>
      ),*/
    },
  ];
  const disabledDate = (current: moment.Moment) => {
    if (!fechaInicio) {
      return current && current >= moment().endOf("day"); // Mientras no haya fecha inicial, deshabilita fechas futuras
    }
    const minDate = fechaInicio.clone().startOf("day");
    const maxDate = fechaInicio.clone().add(6, "months").add(1, "days").endOf("day");
    const today = moment().startOf("day"); // Fecha actual (final del día)

    return current && (current < minDate || current > maxDate || current > today);
  };
  const getCargosTransferencias = async (fechaInicio: Moment, fechaFin: Moment) => {
    setDataEstado(false);
    setDataBoton(true);
    try {
      const res = await fetch(
        url_interna + "api/inventarios/obtener_cargos_logistica_otros_motivos",
        optionsPOST(
          JSON.stringify({
            fechaInicio: fechaInicio!.format("DD/MM/YYYY"),
            fechaFin: fechaFin!.format("DD/MM/YYYY"),
          }),
          {
            "api-token": "apitoken",
          }
        )
      );
      if (isError(res)) throw new Error();
      const respuesta = await res.json();

      if (respuesta.msg !== "err") {
        const respuestas: CargosTransferenciasReporte[] = respuesta.data;
        setDataEstado(true);
        setDataBoton(false);
        setDatos(
          respuestas.map(
            ({ Cod_Bodega, Bodega, Cod_Producto, Cod_MIA, Cod_Barra, Producto, Cantidad,Unidad_Medida, CostoFraccion, key }) => ({
              Cod_Bodega,
              Bodega,
              Cod_Producto,
              Cod_MIA,
              Cod_Barra,
              Producto,
              Cantidad,
              Unidad_Medida,
              CostoFraccion,
              key,
            })
          )
        );
      } else {
        setDataEstado(false);
        setDataBoton(false);
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      setDataEstado(false);
      setDataBoton(false);
      return { msg: "no_conexion", status: false };
    }
  };
  /*  const calcularTotales = () => {
    let total = 0.0;

    filteredData.forEach((item) => {
      total += item.Total;
    });
    total = parseFloat(total.toFixed(2));
    let valor = formateaCantidad(total);
    return { valor };
  };*/
  const handleChangeButton = () => {
    try {
      if (fechaInicio && fechaFin) {
        getCargosTransferencias(fechaInicio, fechaFin);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleClickDownloadData = () => {
    const datosParaExportar = datos.map(({ key, ...resto }) => resto);
    exportToExcel(datosParaExportar, "Cargos Transferencias logistica Otros Motivos");
  };

  return {
    columns,
    filteredData,
    handleChangeButton,
    estadoBotones,
    disabledDate,
    dataEstado,
    getCargosTransferencias,
    searchText,
    optionsPost,
    fechaInicio,
    fechaFin,
    setFechas,
    handleClickDownloadData,
    // calcularTotales,
  };
}
