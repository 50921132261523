import { Moment } from "moment";
import { useEffect, useState } from "react";
import { optionsPOST, url_interna } from "../../../config/constants";

import { useLocalStorage } from "hooks/otros/useLocalStorage";
import { useVerifyResponse } from "hooks/otros/useVerifyReponse";
import moment from "moment";
import { TableColumnsType } from "antd";

import { useLayout } from "hooks/otros/useLayout";

import { useSearchTableNew } from "hooks/otros/useSearchTableNew";
import { useExport } from "hooks/otros/useExport";

type CargosTransferenciasReporte = {
  key: React.Key;
  BodegaReclama: string;
  Fecha: string;
  Usuario: string;
  Cod_MIA: string;
  Producto: string;
  Lab: string;
  Tipo: string;
  Cajas: number;
  Fracciones: number;
  Estado: string;
  Observacion: String;
};

const breadcrumbs = [
  { Label: "Inventarios", Url: "/inventario" },
  { Label: "Logistica", Url: "/inventarios/logistica" },
  { Label: "reclamos" },
];
//const breadcrumbs = [{ Label: "Inventarios", Url: "/inventario" }, { Label: "Productividad" }];

const openKeys = [breadcrumbs[0].Label, breadcrumbs[0].Label + "/" + breadcrumbs[1].Label];
export function useReporteReclamos() {
  useLayout(breadcrumbs, openKeys, breadcrumbs[1].Label + "/" + breadcrumbs[2].Label);
  const [datos, setDatos] = useState<CargosTransferenciasReporte[]>([]);
  const [searchText] = useState("");
  const [filteredData, setFilteredData] = useState<CargosTransferenciasReporte[]>([]);
  const { getColumnSearchPropsNew } = useSearchTableNew<CargosTransferenciasReporte>();
  const { exportToExcel } = useExport();
  let { optionsPost } = useLocalStorage();
  const { isError } = useVerifyResponse();
  const [[fechaInicio, fechaFin], setFechas] = useState<[Moment | null, Moment | null]>([null, null]);
  const [dataEstado, setDataEstado] = useState<boolean>(false);

  const [estadoBotones, setDataBoton] = useState<boolean>(false);



  useEffect(() => {
    if (!searchText) {
      setFilteredData(datos); // Si no hay texto de búsqueda, muestra todos los datos
    } else {
      const filtered = datos.filter((item) => item.Producto.toLowerCase().includes(searchText.toLowerCase()));
      setFilteredData(filtered);
    }
  }, [searchText, datos]);

  const columns: TableColumnsType<CargosTransferenciasReporte> = [
    {
      title: "Bodega",
      dataIndex: "BodegaReclama",
      className: "letraPequenaVisor2",
      key: "Nombre",
      ...getColumnSearchPropsNew("BodegaReclama"),
    },
    {
      title: "Fecha",
      dataIndex: "Fecha",
      className: "letraPequenaVisor2",
      key: "Fecha",
    },
    {
      title: "Usuario",
      dataIndex: "Usuario",
      key: "Usuario",
      className: "letraPequenaVisor2",
    },

    {
      title: "Cod",
      dataIndex: "Cod_MIA",
      key: "Cod_MIA",
      className: "letraPequenaVisor2",
      width: "75px",
    },
    {
      title: "Producto",
      dataIndex: "Producto",
      key: "Producto",
      className: "letraPequenaVisor2",
      ...getColumnSearchPropsNew("Producto"),
    },
    {
      title: "Lab",
      dataIndex: "Lab",
      key: "Lab",
      className: "letraPequenaVisor2",
    },
    {
      title: "Tipo",
      dataIndex: "Tipo",
      key: "Tipo",
      className: "letraPequenaVisor2",
      filters: [...new Set(filteredData.map(({ Tipo }) => Tipo))].map((Laboratorio) => ({
        text: Laboratorio,
        value: Laboratorio,
      })),
      filterSearch: true,
      onFilter: (value, record) => value === record.Tipo,
    },
    {
      title: "Cajas",
      dataIndex: "Cajas",
      key: "Cajas",
      width: "75px",
      align: "right",
      className: "letraPequenaVisor2",
    },
    {
      title: "Fracciones",
      dataIndex: "Fracciones",
      key: "Fracciones",
      width: "100px",
      align: "right",
      className: "letraPequenaVisor2",
    },
    {
      title: "Estado",
      dataIndex: "Estado",
      key: "Estado",
      className: "letraPequenaVisor2",
      filters: [...new Set(filteredData.map(({ Estado }) => Estado))].map((Laboratorio) => ({
        text: Laboratorio,
        value: Laboratorio,
      })),
      filterSearch: true,
      onFilter: (value, record) => value === record.Estado,
    },
    
    {
      title: "Observación",
      dataIndex: "Observacion",
      key: "Observacion",
      className: "letraPequenaVisor2",
    },
  ];
  const disabledDate = (current: moment.Moment) => {
    if (!fechaInicio) {
      return current && current > moment().endOf("day"); // Mientras no haya fecha inicial, deshabilita fechas futuras
    }
    const minDate = fechaInicio.clone().startOf("day");
    const maxDate = fechaInicio.clone().add(6, "months").add(1, "days").endOf("day");
    const today = moment().endOf("day"); // Fecha actual (final del día)

    return current && (current < minDate || current > maxDate || current > today);
  };
  const getCargosTransferencias = async (fechaInicio: Moment, fechaFin: Moment) => {
    setDataEstado(false);
    setDataBoton(true);
    try {
      const res = await fetch(
        url_interna + "api/inventarios/obtener_reporte_reclamos",
        optionsPOST(
          JSON.stringify({ fechaInicio: fechaInicio!.format("DD/MM/YYYY"), fechaFin: fechaFin!.format("DD/MM/YYYY") }),
          {
            "api-token": "apitoken",
          }
        )
      );
      if (isError(res)) throw new Error();
      const respuesta = await res.json();

      if (respuesta.msg !== "err") {
        const respuestas: CargosTransferenciasReporte[] = respuesta.data;
        setDataEstado(true);
        setDataBoton(false);
        setDatos(
          respuestas.map(
            ({
              key,
              BodegaReclama,
              Fecha,
              Usuario,
              Cod_MIA,
              Producto,
              Lab,
              Tipo,
              Cajas,
              Fracciones,
              Estado,
              Observacion,
            }) => ({
              key,
              BodegaReclama,
              Fecha,
              Usuario,
              Cod_MIA,
              Producto,
              Lab,
              Tipo,
              Cajas,
              Fracciones,
              Estado,
              Observacion,
            })
          )
        );
      } else {
        setDataEstado(false);
        setDataBoton(false);
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      setDataEstado(false);
      setDataBoton(false);
      return { msg: "no_conexion", status: false };
    }
  };

  const handleChangeButton = () => {
    try {
      if (fechaInicio && fechaFin) {
        getCargosTransferencias(fechaInicio, fechaFin);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleClickDownloadData = () => {
    const datosParaExportar = datos.map(({ key, ...resto }) => resto);
    exportToExcel(
      datosParaExportar,
      "Reporte Reclamos - " + fechaInicio!.format("DD/MM/YYYY") + " - " + fechaFin!.format("DD/MM/YYYY")
    );
  };

  return {
    columns,
    filteredData,
    handleChangeButton,
    estadoBotones,
    disabledDate,
    dataEstado,
    getCargosTransferencias,
    searchText,
    optionsPost,
    fechaInicio,
    fechaFin,
    setFechas,
    handleClickDownloadData,
  };
}
