import { Button, Col, DatePicker, Row, Select, Space, Table } from "antd"
import { ButtonExcel } from "components/otros/buttons"
import { useFacturasPendientesGruposProveedores } from "hooks/reportesGeneral/useFacturasPendientesGruposProveedores"

export function FacturasPendientesGruposProveedores() {
  const {
    grupos,
    pageSize,
    setPageSize,
    handleDownload,
   /* fechaCorte,
    setFechaCorte,*/
    fechaInicio,
    fechaFin,
    setFechas,
    disabledDate,
    handleChangeButton,
    estadoBotones,
    columnsTable,
    documentosPendientes,
    selectedGrupo,
    setSelectedGrupo,
  } = useFacturasPendientesGruposProveedores()

  return (
    <Row
      wrap
      style={{ padding: "16px", marginRight: "0px", backgroundColor: "#fff", display: "flex", alignItems: "center" }}
      gutter={[16, 16]}
    >
      <Col xs={24} sm={12} md={6} lg={6} xl={8} style={{ width: "100%" }}>
        <Space style={{ width: "100%" }}>
          <>Grupo:</>
          <Select
            style={{ width: 150 }}
            options={grupos?.map(g => ({ value: g.cod_grupo, label: g.grupo }))}
            onChange={grupo => {
              setSelectedGrupo(grupo)
            }}
          />
        </Space>
      </Col>
      <Col xs={24} sm={12} md={12} lg={12} xl={12}>
      <DatePicker.RangePicker
          value={[fechaInicio, fechaFin]} // Mantiene el estado sincronizado
          onCalendarChange={(dates) => {
            if (dates && dates[0]) {
              setFechas([dates[0], dates[1]]); // Actualiza las fechas seleccionadas
            } else {
              setFechas([null, null]); // Permite reiniciar el rango al borrar o seleccionar nuevo rango
            }
          }}
          onChange={(dates) => {
            if (!dates) {
              setFechas([null, null]); // Reinicia el rango cuando se borra
            }
          }}
          disabledDate={disabledDate}
        />
        <Button
          type='primary'
          style={{ marginLeft: "5px" }}
          //icon={<PoweroffOutlined />}
          loading={estadoBotones}
          disabled={fechaInicio === null || fechaFin === null || selectedGrupo===0}
          onClick={handleChangeButton}
        >
          Generar reporte
        </Button>
      </Col>
     <Col xs={24} sm={12} md={4} lg={4} xl={4}>
        <Row
          gutter={[10, 10]}
          style={{
            marginBottom: "20px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
        <ButtonExcel buttonProps={{ onClick: handleDownload }} />
        </Row>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Table
          columns={columnsTable}
          scroll={{ x: true }}
          dataSource={documentosPendientes}
          rowKey="numeracion"
          pagination={{
            pageSize,
            size: "default",
            onShowSizeChange: (_, size) => setPageSize(size),
            pageSizeOptions: [5, 10, 20, 50, 100],
          }}
        />
      </Col>
    </Row>
  )
}
