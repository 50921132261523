import { Affix, BackTop, Col, DatePicker, Form, Row } from "antd"
import { BodegasTables } from "components/franquiciados/bodegas-tables"
import { CalculosFinalesFactura } from "components/franquiciados/calculos-finales"
import { TotalAsumido } from "components/franquiciados/total-asumido"
import { ObtenerGastos } from "components/franquiciados/obtener-gastos"
import { BusquedaBodegaFranquiciado } from "components/franquiciados/busqueda-bodega-franquiciado"
import { ObtenerDeudas } from "components/franquiciados/obtener-deudas"
import { ButtonReload, ButtonSave } from "components/otros/buttons"

import { useScreenSize } from "hooks/otros/useScreenSize"
import {
  calcularTotalFinal,
  calcularTotalGastosFijos,
  hacerCalculosFinales,
  calcularTotalBonos,
  usePago,
  TipoSeleccion,
} from "hooks/Franquiciados/usePago"

import { ContextFormPago } from "context/franquiciados/form-pago-context"

import "moment/locale/es"

import style from "pages/contentfranquiciados/contentpago/pago.module.css"

export function Pago() {
  const {
    loading,
    form,
    dataInicial,
    dataPrincipal,
    handleChangeOtros,
    handleFinishForm,
    handleRemoveBono,
    handleRemoveDeuda,
    fillDataPrincipal,
    handleOkButtonGrabar,
    tipoSeleccion,
    setTipoSeleccion,
    handleReloadDependientes,
    handleReloadCargoEmpleados,
    disabledGrabar,
    handleReloadPrestamos,
    handleChangeSueldo,
    setDisabledGrabar,
    handleReloadBodegas,
    handleDeleteDependiente,
    handleChangeFondosReserva,
  } = usePago()

  const { width } = useScreenSize()

  return (
    <ContextFormPago form={form} loading={loading}>
      <Form form={form} onFinish={handleFinishForm} scrollToFirstError>
        <Affix offsetTop={64}>
          <Row wrap className={style.rowStyle} gutter={[16, width <= 768 ? 0 : 16]}>
            <Col xs={24} sm={8} md={6} lg={6} xl={5}>
              <Form.Item name="mes" label="Mes">
                <DatePicker
                  picker="month"
                  placeholder="Seleccionar mes"
                  format="MMMM [del] YYYY"
                  onChange={() => {
                    setDisabledGrabar(true)
                    fillDataPrincipal(tipoSeleccion)
                  }}
                  inputReadOnly
                  allowClear={false}
                />
              </Form.Item>
            </Col>
            <BusquedaBodegaFranquiciado
              tipoSeleccion={tipoSeleccion}
              onChangeTipoSeleccion={e => setTipoSeleccion(e.target.value)}
              onChangeSelect={() => {
                setDisabledGrabar(true)
                if (tipoSeleccion === TipoSeleccion.Bodega)
                  form.setFieldValue(
                    "franquiciado",
                    dataInicial.bodegas.find(({ value }) => value === form.getFieldValue("bodega"))?.franquiciado
                  )
                fillDataPrincipal(tipoSeleccion)
              }}
              bodegas={dataInicial.bodegas.map(({ value, label }) => ({ value, label }))}
              franquiciados={dataInicial.franquiciados}
            />
            <Col xs={24} sm={24} md={6} lg={6} xl={4}>
              <ButtonSave buttonProps={{ onClick: handleOkButtonGrabar, disabled: disabledGrabar }} />
              {/* {dataPrincipal.cod_pago > 0 ? "Modificar" : "Guardar"}
              </ButtonSave> */}
            </Col>
          </Row>
        </Affix>
        <Row wrap className={style.rowStyle} gutter={[16, 16]}>
          {dataPrincipal.cod_pago > 0 && (
            <Col xs={24} sm={24} md={12} lg={10} xl={8} style={{ margin: "auto" }}>
              <ButtonReload buttonProps={{ onClick: handleReloadBodegas }}>Recargar bodegas</ButtonReload>
            </Col>
          )}
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <BodegasTables
              handleChangeFondosReserva={handleChangeFondosReserva}
              handleChangeSueldo={handleChangeSueldo}
              handleChangeOtros={handleChangeOtros}
              loading={loading}
              reload={dataPrincipal.cod_pago > 0}
              onReloadBodega={handleReloadDependientes}
              onReloadCargoEmpleados={handleReloadCargoEmpleados}
              onDeleteEmpleado={handleDeleteDependiente}
            />
          </Col>
          {dataPrincipal.cod_pago > 0 && (
            <Col xs={24} sm={24} md={12} lg={10} xl={8} style={{ margin: "auto" }}>
              <ButtonReload buttonProps={{ onClick: handleReloadPrestamos }}>Recargar préstamos</ButtonReload>
            </Col>
          )}
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            {dataPrincipal.deudas.length > 0 && <ObtenerDeudas handleRemove={handleRemoveDeuda} />}
          </Col>
          <Col xs={24} sm={24} md={15} lg={15} xl={15}>
            <ObtenerGastos
              n_bodegas={dataPrincipal.bodegas.length ?? 0}
              calcularTotalGastosFijos={calcularTotalGastosFijos}
              calcularTotalFinal={calcularTotalFinal}
              calculosFinales={hacerCalculosFinales}
            />
          </Col>
          <Col xs={24} sm={24} md={9} lg={9} xl={9}>
            <TotalAsumido style={style.collapseStyle} />
          </Col>
        </Row>
        <CalculosFinalesFactura
          onRemoveBono={handleRemoveBono}
          onChangeValuebono={() => calcularTotalBonos(form)}
          mesActual={form.getFieldValue("mes")}
        />
      </Form>
      <BackTop />
    </ContextFormPago>
  )
}

export default Pago
