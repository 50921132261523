import { Col, Row, Space, Typography, DatePicker, Button, Spin, Table } from 'antd'
import { useLayout } from 'hooks/otros/useLayout'
import { FileExcelOutlined } from "@ant-design/icons"
import React from 'react'
import moment from 'moment'
import { AcumulacionPendiente, useReporteAcumulacionPendiente } from 'hooks/productos/useReporteAcumulacionPendiente'

const {Title}=Typography
const { RangePicker } = DatePicker

const breadcrumbs = [
  { Label: "Productos", Url: "productos" },
  { Label: "Promociones", Url: "/productos/promociones" },
  { Label: "Acumulaciones Pendientes" },
]
const openKeys = [breadcrumbs[0].Label, `${breadcrumbs[0].Label}/${breadcrumbs[1].Label}`]
const selectKeySider = `${breadcrumbs[1].Label}/${breadcrumbs[2].Label}`
export default function ReporteAcumulacionPendiente() {
  useLayout(breadcrumbs, openKeys, selectKeySider)

  const { disableFutureDates, disableFutureTimes,handleDateChange,fechasSeleccionadas,estado,columns,DataAcumulacionPendiente, handleDownload}=useReporteAcumulacionPendiente()
  return (
   <>
     <Row style={{ background: "white", padding: "5px", paddingLeft: "10px" }}>
            <Col xs={24} sm={24} md={12}>
              <Title level={3}>Reporte de Acumulaciones Pendientes MF</Title>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Row justify={"end"}>
                <Col sm={24} md={14}>
                  <Row style={{ fontSize: "10px", marginRight: "5px" }}>Fechas:</Row>
                  <Row style={{ marginTop: "5px" }}>
                    <Space>
                      <RangePicker
                        size="small"
                        ranges={{
                          Today: [moment(), moment()],
                          'This Month': [moment().startOf('month'), moment().endOf('month')],
                        }}
                        showTime
                        format="YYYY/MM/DD HH:mm:ss"
                        disabledDate={disableFutureDates}
                        disabledTime={disableFutureTimes}
                        defaultValue={fechasSeleccionadas}
                        //format={"DD/MM/YYYY"}
                        onChange={handleDateChange}
                        style={{ marginLeft: "2px" }}
                      />
                    </Space>
                  </Row>
                </Col>
                <Col style={{ marginTop: "12px", marginLeft: "3px" }}>
                  <Button
                    type="primary"
                    style={{ marginRight: "2px", marginTop: "1px", backgroundColor: "#00B050", borderColor: "#00B050" }}
                    onClick={handleDownload}
                  >
                    Excel <FileExcelOutlined />
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          {!estado && (
        <Row
          style={{
            justifyContent: "center",
            textAlign: "center",
            display: "flex",
            background: "white",
            padding: "40px",
            height: "100%",
            paddingTop: "80px",
          }}
        >
          <Spin style={{ fontSize: "20px" }} />
        </Row>
      )}
      {estado && (
        <Row style={{ alignItems: "center", background: "white", padding: "5px", height: "100%" }}>
          <Table
            style={{ width: "100%", height: "100%", overflowX: "auto" }}
            columns={columns as AcumulacionPendiente[]}
            dataSource={DataAcumulacionPendiente}
            //rowClassName={(record, index) => 'miFilaSeleccionada'}
          />
        </Row>
      )}
   </>
  )
}
